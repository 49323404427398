class DragBuffer {
  constructor () {
    this.clear()
  }

  getDragData () {
    return this.currentDragData
  }

  getDragType () {
    return this.currentDragType
  }

  setupData (type, data) {
    this.currentDragType = type
    this.currentDragData = data
  }

  isValid (event) {
    if (!event || !event.dataTransfer) return

    const { types } = event.dataTransfer
    return this.currentDragType && types.length > 0
  }

  clear () {
    this.currentDragData = null
    this.currentDragType = null
  }
}

let buffer = null

export default {
  created () {
    const { name } = this.$options
    if (name !== 'Contacts') {
      this.dragBuffer = buffer
      return
    }

    this.dragBuffer = buffer || (buffer = new DragBuffer())
  },
  beforeDestroy () {
    const { name } = this.$options
    if (name !== 'Contacts') return

    this.dragBuffer.clear()
    buffer = this.dragBuffer = null
  },
}
