import DOMUtils from '@/utils/DOM'
import PopoutUtils from '@/components/UI/Popouts/Utils'

interface Offset {
  vertical: any;
  horizontal: any;
}

interface AlignStyle {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  [key: string]: string | undefined;
}

export const getArrowStyle = ({ pointer, offset, alignBy, trigger, options }: { pointer: HTMLElement | null; offset: any; alignBy: string; trigger: HTMLElement; options: any }) => {
  if (!pointer) return

  const pointerCenter = DOMUtils.getCenter(pointer)
  if (!pointerCenter) return

  const { left: triggerLeft } = trigger.getBoundingClientRect()

  options = options || { arrowSize: 10 }
  offset = offset || { vertical: -5, horizontal: 20 }

  const translateSign = alignBy === 'bottom' ? 1 : -1

  const style = {
    'border-width': `${options.arrowSize}px`,
    [alignBy]: '100%',
    left: pointerCenter.x - offset.horizontal - triggerLeft + 'px',
    'margin-left': `-${options.arrowSize}px`,
    [`border-${alignBy}-color`]: '#fff',
    transform: `translate(0, ${2 * translateSign}px)`,
  }

  return style
}

export const getCenteredAlign = (triggerElement: HTMLElement, menuElement: HTMLElement): AlignStyle => {
  const { width: tWidth } = triggerElement.getBoundingClientRect()
  const rect = menuElement.getBoundingClientRect()
  const { width: mWidth } = rect
  let { left: mLeft, right: mRight } = rect

  const offset = -Math.abs(mWidth / 2 - tWidth / 2)

  const boundAncestor = (PopoutUtils.determineBoundAncestor(menuElement) || document.body) as HTMLElement
  const { left: bLeft, right: bRight } = boundAncestor.getBoundingClientRect()

  mLeft += offset
  mRight += offset

  const offsetFromLeft = (mLeft - bLeft) - 10
  const offsetFromRight = (bRight - mRight) - 10

  const overflowFix = offsetFromRight < 0
    ? offsetFromRight
    : offsetFromLeft < 0 ? -offsetFromLeft : 0

  return {
    left: (offset + overflowFix) + 'px',
  } as AlignStyle
}

export const fixAlignStyle = (menuElement: HTMLElement | null, offset: Offset, currentStyle: AlignStyle): AlignStyle | null => {
  if (!menuElement) return null

  const boundsOffset = 15

  let result: AlignStyle | null = null
  const menuBox = menuElement.getBoundingClientRect()

  const sides = Object.keys(currentStyle)
  sides.forEach(side => {
    switch (side) {
      case 'left': {
        if (menuBox.left >= 0) break

        const value = boundsOffset + offset.horizontal + (-menuBox.left)
        result = result || {}
        result.left = `${value}px`
        break
      }
      case 'right': {
        if (menuBox.right <= window.innerWidth) break

        const value = boundsOffset + offset.horizontal + (menuBox.right - window.innerWidth)
        result = result || {}
        result.right = `${value}px`
        break
      }
      case 'top': {
        if (menuBox.top >= 0) break

        const value = boundsOffset + offset.vertical + (-menuBox.top)
        result = result || {}
        result.top = `${value}px`
        break
      }
      case 'bottom': {
        if (menuBox.bottom <= window.innerHeight) break

        const value = boundsOffset + offset.vertical + (menuBox.bottom - window.innerHeight)
        result = result || {}
        result.bottom = `${value}px`
        break
      }
    }
  })
  return result
}

export const getAlignStyle = (target: HTMLElement, relativeTo: string | HTMLElement, offset: Offset, dropside?: string, horizontalDropsize?: string) => {
  const alignStyle: AlignStyle = {}

  if (relativeTo === 'window') {
    const { top, height, left, width } = target.getBoundingClientRect()
    const centerY = top + (height / 2)
    const centerX = left + (width / 2)

    let vertical = offset && offset.vertical
    vertical = isNaN(vertical) ? 5 : +vertical

    const halfHeight = window.innerHeight / 2
    alignStyle[dropside || (centerY > halfHeight ? 'bottom' : 'top')] = `calc(100% + ${vertical}px)`

    const horizontal = offset?.horizontal || 0
    const halfWidth = window.innerWidth / 2
    alignStyle[horizontalDropsize || (centerX <= halfWidth ? 'left' : 'right')] = `${horizontal}px`
  } else {
    // TODO
    // let parent = target.parentNode
    // while (parent) {
    //   const match = parent.matches(relativeTo)
    //   if (match) {
    //     console.log('match')
    //     break
    //   }

    //   parent = parent.parentNode
    // }
  }
  return alignStyle
}
