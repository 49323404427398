
































import { contactsStore } from '@/store'
import { ContactOnlineType } from '@/store/modules/contacts/types'
import type { Chat } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseAvatar from '@/components/UI/BaseAvatar.vue'

@Component({
  inheritAttrs: false,
  components: {
    BaseAvatar,
    IconSmartphoneFill: () => import('@/components/UI/icons/IconSmartphoneFill.vue'),
  },
})
export default class BaseEntityAvatar extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly id!: string

  @Prop({
    type: String,
    default: '',
  }) readonly indicatorSize!: string

  @Prop({
    type: Boolean,
    default: false,
  }) readonly showOnlineIndicator!: boolean

  private get entity (): Chat {
    return this.$store.getters.entity(this.id)
  }

  private get isGroup (): boolean {
    const t = this.entity.chatType
    return t === 'task' || t === 'group'
  }

  private get isAfk (): boolean {
    return contactsStore.getters.contactIsAfk(this.id)
  }

  private get onlineType (): ContactOnlineType {
    return contactsStore.getters.contactOnlineType(this.id)
  }

  private get showIndicator (): boolean {
    if (!this.showOnlineIndicator) return false
    if (this.isGroup) return false
    return this.showIndicatorWeb || this.showIndicatorMobile
  }

  private get showIndicatorWeb (): boolean {
    return this.onlineType === 'web'
  }

  private get showIndicatorMobile (): boolean {
    return this.onlineType === 'mobile'
  }

  private get indicatorStyle (): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {}
    if (this.indicatorSize) {
      style.height = style.width = this.indicatorSize
    }
    return style
  }
}
