//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { mapGetters } from 'vuex'
import { highlightMatches, preventHtmlTags, parseEmoji } from '@/utils'
import BaseEntityAvatar from '@/components/UI/BaseEntityAvatar.vue'
import ContactStatusBadges from '@/components/UI/ContactStatusBadges.vue'
import DragBufferMixin from './DragBufferMixin'
import { showLeaveGroupDialog } from '@/components/Modals/Default/Dialog/Presets'
import ContactCardControls from './ContactCardControls.vue'

import {
  contactsStore,
  uiStore,
  teamsStore,
} from '@/store'

export default {
  name: 'ContactCard',
  mixins: [DragBufferMixin],
  components: {
    BaseEntityAvatar,
    ContactCardControls,
    ContactStatusBadges,
  },
  props: {
    filter: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'getUserId',
    ]),
    showContactsSectionsRelated () {
      return uiStore.getters.showContactsSectionsRelated
    },
    icon () {
      return contactsStore.getters.contactIcon(this.data.jid)
    },
    name () {
      return parseEmoji(highlightMatches(preventHtmlTags(this.data.displayName), this.filter))
    },
    role () {
      return parseEmoji(highlightMatches(preventHtmlTags(this.data.role), this.filter))
    },
    isGroup () {
      return this.$store.getters.chatType(this.data.jid) === 'group'
    },
    isMe () {
      return this.getUserId === this.data.jid
    },
    canDrag () {
      if (this.data.changeableFields && this.data.changeableFields.indexOf('section') !== -1) {
        return this.draggable
      }

      return false
    },
    canDelete () {
      if (this.isMe) return false
      if (this.isGroup) return this.data.canChangeSettings
      return this.data.canDelete
    },
    canChangeInfo () {
      return this.isGroup
        ? this.data.canChangeSettings
        : this.data.changeableFields?.includes('role') || this.data.changeableFields?.includes('status')
    },
  },
  methods: {
    openProfileAndChat () {
      const instance = this.isGroup ? 'group-profile' : 'contact-profile'
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: this.data.jid,
        },
      })
      uiStore.actions.switchRightBarInstance({
        instance,
        payload: this.data.jid,
      })
      uiStore.actions.resetMiddleColumnInstance()
    },
    async remove () {
      const { jid, displayName } = this.data

      if (this.isMe) return
      if (this.isGroup) {
        showLeaveGroupDialog(jid)
        return
      }

      const dialogOptions = {
        caption: this.$t('profiles.kick.caption'),
        icon: this.icon,
        content: this.$t('profiles.kick.content'),
        formatData: { name: displayName },
        acceptCallback: async () => {
          await api.contacts.delete(jid)
          await contactsStore.actions.deleteContact(jid)
        },
        acceptLabel: this.$t('profiles.kick.acceptLabel'),
        acceptError: this.$t('profiles.kick.acceptError'),
      }

      uiStore.actions.showModal({
        instance: 'Dialog',
        payload: dialogOptions,
      })
    },
    edit () {
      if (this.isGroup) {
        uiStore.actions.showModal({
          instance: 'GroupForm',
          payload: { type: 'edit', jid: this.data.jid },
        })
        return
      }

      if (this.isMe) {
        uiStore.actions.showModal({ instance: 'EditProfile' })
        return
      }

      uiStore.actions.showModal({
        instance: 'EditContact',
        payload: this.data.jid,
      })
    },
    handleDragStart (event) {
      // if (!this.section) return

      event.dataTransfer.setData('Text', this.data.displayName || 'payload')

      this.dragBuffer.setupData('contact', {
        section: this.section,
        jid: this.data.jid,
      })
    },
    handleDragEnd () {
      this.dragBuffer.clear()
    },
  },
}
