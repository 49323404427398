//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store, { groupsStore, teamsStore, uiStore } from '@/store'
import {
  highlightMatches,
  preventHtmlTags,
  getIconUrl,
} from '@/utils'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'GroupInstanceRow',
  components: {
    BaseSpinner,
  },
  props: {
    contactJid: {
      type: String,
      default: store.getters.getUserId,
    },
    data: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    icon () {
      return getIconUrl(this.data.icons)
    },
    name () {
      return highlightMatches(preventHtmlTags(this.data.displayName), this.filter)
    },
    membersCount () {
      return this.data.numMembers + (this.isIn ? 1 : 0)
    },
    isIn () {
      return groupsStore.getters.isInGroup(this.data.jid, this.contactJid)
    },
  },
  methods: {
    handleNameClick () {
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: this.data.jid,
        },
      })
      uiStore.actions.hideModal()
    },
    async join () {
      this.loading = true

      if (this.isIn) {
        const payload = {
          groupId: this.data.jid,
          memberId: this.contactJid,
        }
        await groupsStore.actions.deleteMember(payload)
      } else {
        const payload = {
          groupId: this.data.jid,
          memberId: this.contactJid,
          status: 'member',
        }
        await groupsStore.actions.addMember(payload)
      }

      this.loading = false
    },
  },
}
