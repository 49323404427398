//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    numActiveFilters: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data () {
    return {
      showFilters: false,
    }
  },
  computed: {
    hasActiveFilters () {
      return this.numActiveFilters > 0
    },
    title () {
      let title = this.$t('tasks.filters.title')
      if (this.hasActiveFilters) {
        const counter = `(${this.numActiveFilters})`
        title += ` ${counter}`
      }
      return title
    },
  },
}
