//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { throttle, scroll } from 'quasar'

import SearchInput from '@/components/UI/SearchInput'

import DragBufferMixin from './DragBufferMixin'
import Menu from './Menu'
import Section from './Section'

import { defaultLogger } from '@/loggers'
import { sectionsStore, teamsStore, uiStore } from '@/store'

const { getScrollTarget, setScrollPosition } = scroll

export default {
  name: 'Contacts',
  components: {
    search: SearchInput,

    Menu,
    Section,
  },
  mixins: [DragBufferMixin],
  data () {
    return {
      filter: '',

      draggedHotSpotsIndex: -1,
      draggedHotSpotsDir: 0,
    }
  },
  created () {
    this.throttledHandleDragOver = throttle(this.handleDragOver, 15)
  },
  computed: {
    showContactsSectionsRelated () {
      return uiStore.getters.showContactsSectionsRelated
    },
    currentRightBarPayload () {
      return uiStore.getters.currentRightBarPayload
    },
    sections () {
      const sorted = [...sectionsStore.getters.contactSectionsSorted()]

      // server does not return 'no section' object, so create it ourselves
      sorted.push({
        uid: undefined,
        name: this.$t('modals.AddContact.noSection'),
      })

      return sorted
    },
    hasRights () {
      return teamsStore.getters.currentTeam.me.canAddToTeam
    },
  },
  watch: {
    currentRightBarPayload: {
      immediate: true,
      async handler (newValue) {
        await this.$nextTick() // wait for mount child components

        if (!newValue) return

        const value = newValue.replace(/^sd-/, '') // remove useless section prefix from payload

        if (!this.sections.find(sec => sec.uid === value)) return

        const section = this.$refs[value][0]

        if (section.isCollapsed) section.toggleCollapse()

        await this.$nextTick() // wait for rerender child components
        this.scrollToGroup(value)
      },
    },
  },
  methods: {
    async handleDragEnd (uid) {
      const relativeSection = this.sections[this.draggedHotSpotsIndex]
      if (!relativeSection) return

      if (relativeSection.uid === uid || this.draggedHotSpotsDir === 0) return

      const subjectSection = sectionsStore.getters.section(uid)
      if (!subjectSection) return

      const before = this.draggedHotSpotsDir > 0
      defaultLogger.log('draggedHotStop', this.draggedHotSpotsDir)

      this.draggedHotSpotsIndex = -1
      this.draggedHotSpotsDir = 0

      try {
        await sectionsStore.actions.move({
          a: uid,
          direct: before ? 'before' : 'after',
          b: relativeSection.uid,
        })
      } catch (e) {
        defaultLogger.error('Handle drag end', e)
      }
    },
    /**
     * Get hotspot direction by index of section
     * @returns 'none' | 'top' | 'bottom'
     */
    getHotSpot (index) {
      const retSpot =
        this.draggedHotSpotsIndex === index && this.draggedHotSpotsDir !== 0
      if (!retSpot) return 'none'
      return this.draggedHotSpotsDir === 1 ? 'top' : 'bottom'
    },
    /**
     * Handling drag over event
     * @see DragBufferMixin
     */
    handleDragOver (event) {
      if (!this.dragBuffer || !this.dragBuffer.isValid(event)) return

      if (this.dragBuffer.getDragType() !== 'section') return

      const data = this.dragBuffer.getDragData()
      if (!data) return

      const { index: draggedSectionIndex } = data

      const { y } = event
      let { index, dir } = this.getClosestSection({ y, except: -1 })
      if (index === -1) return

      const indexDiff = draggedSectionIndex - index
      dir = Math.abs(indexDiff) <= 1 ? indexDiff : dir

      this.draggedHotSpotsIndex = index
      this.draggedHotSpotsDir = dir
    },
    getClosestSection ({ y, except }) {
      let index = -1
      let minDistance = null
      let dir = 0

      for (let i = 0; i < this.sections.length; i++) {
        if (i === except) continue

        const section = this.sections[i]
        const sectionElm = document.querySelector(`div[id="${section.uid}"]`)
        if (!sectionElm) continue

        const { top, height } = sectionElm.firstChild.getBoundingClientRect()
        const center = top + height / 2

        const distance = center - y
        const abs = Math.abs(distance)
        if (minDistance === null || minDistance > abs) {
          dir = Math.sign(distance)
          minDistance = abs

          index = i
        }
      }
      return { index, dir }
    },
    scrollToGroup (id) {
      const target = getScrollTarget(this.$refs[id][0].$el)
      const offset = this.$refs[id][0].$el.offsetTop
      const duration = 300
      setScrollPosition(target, offset, duration)
    },
  },
}
