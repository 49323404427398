import api from '@/api/v3'
import store, {
  contactsStore,
  groupsStore,
  uiStore,
} from '@/store'
import { logout } from '@/utils'
import { getRandomIconPlaceholder } from '@/api/v3/DataAdapter'
import i18n from '@/i18n'
// import { initiateMainComponent } from '@/utils/Common'
// import Onboarding from '@/auth/onboarding/index.vue'

export const logoutDialog = () => {
  const { getters } = store

  const { displayName, jid } = getters.profile

  const dialogOptions = {
    caption: i18n.t('modals.Dialog.logout.caption').toString(),
    icon: contactsStore.getters.contactIcon(jid) || getRandomIconPlaceholder('contact'),
    content: i18n.t('modals.Dialog.logout.content').toString(),
    formatData: { name: displayName },
    acceptCallback: logout,
    acceptLabel: i18n.t('modals.Dialog.logout.acceptLabel').toString(),
  }

  uiStore.actions.showModal({
    instance: 'Dialog',
    payload: dialogOptions,
  })
}

// export const changeServerDialog = () => {
//   initiateMainComponent(
//     Onboarding,
//     { loginAction: true, onEnd: move, serverInput: true }
//   )
// }

export const showLeaveGroupDialog = (chatId: string) => {
  const { getters } = store

  const group = groupsStore.state.data[chatId]
  if (!group) return

  const dialogOptions = {
    caption: i18n.t('modals.Dialog.leaveGroup.caption').toString(),
    icon: groupsStore.getters.icon(chatId),
    content: i18n.t('modals.Dialog.leaveGroup.content').toString(),
    formatData: { name: group.displayName },
    acceptCallback: async () => {
      await api.groups.deleteMember(chatId, getters.getUserId)
    },
    acceptLabel: i18n.t('modals.Dialog.leaveGroup.acceptLabel').toString(),
    acceptError: i18n.t('modals.Dialog.leaveGroup.acceptError').toString(),
  }

  uiStore.actions.showModal({
    instance: 'Dialog',
    payload: dialogOptions,
  })
}
