// import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import SliderChecker from '@/components/UI/CheckboxSlider/SliderChecker.jsx'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
const MarkIcon = createSVGComponent({ icon: 'mark_filled', size: 16, permanentlyActive: true })

const renderLinkOption = (h, option, parent) => {
  const { name, action, fakeLink } = option
  const isActionIsString = typeof action === 'string'
  const href = isActionIsString ? action : (fakeLink ?? '#')

  const handler = e => {
    if (!isActionIsString) {
      e.stopPropagation()
      e.preventDefault()
      action && action()
    }
    parent.closeWindow()
  }

  return (
    <a
      class="option"
      target="_blank"
      href={href}
      onMousedown={parent.onMouseDownHandler}
      onClick={handler}
    >
      {name}
    </a>
  )
}

const renderToggleOption = (h, { option, parent, directives }) => {
  const { name, action, active } = option
  return (
    <li
      class="option option--toggler o-flex-column"
      onMousedown={parent.onMouseDownHandler}
      {...{ directives }}
    >
      <div class="o-flex-row">{name}</div>
      <div>
        <SliderChecker onClick={action} width="32" height="16" thumbSize="10" value={active} textState="true" />
      </div>
    </li>
  )
}

const renderPickerOption = (h, { option, parent, directives }) => {
  const { name, action, variants, selectedIndex, variantName, variantKey, maxVariants, variantsDescriptions, descriptionWidth } = option

  const handler = e => {
    if (!action) return

    const target = e.target || e.currentTarget
    const index = target && target.getAttribute('data-index')
    // if (!index) return

    e.stopPropagation()
    action(variantKey ? index : +index)
    parent.closeWindow()
  }

  const variantNodes = (Array.isArray(variants) ? variants : []).map((variant, index) => {
    const isSelected = selectedIndex === index

    let className = 'option--selector__variant'
    isSelected && (className += ' selected')

    if (!variantsDescriptions) {
      className += ' o-flex-row'
      return (
        <div
          key={variantKey ? variant[variantKey] : variant}
          class={className}
          data-index={variantKey ? variant[variantKey] : index}
          onMousedown={parent.onMouseDownHandler}
          onClick={handler}
        >
          <div class="o-text-cutter">{variantName ? variant[variantName] : variant}</div>
          {isSelected && <MarkIcon />}
        </div>
      )
    }

    className += ' option--selector__variant--description'
    return (
      <div
        key={variantKey ? variant[variantKey] : variant}
        class={className}
        data-index={variantKey ? variant[variantKey] : index}
        onMousedown={parent.onMouseDownHandler}
        onClick={handler}
        style={descriptionWidth && { maxWidth: 'none' }}
      >
        <div class="caption o-flex-row">
          <div class={'o-text-cutter' + (isSelected ? ' o-text-color' : '')}>{variantName ? variant[variantName] : variant}</div>
          {isSelected && <MarkIcon />}
        </div>
        <div class="description" style={descriptionWidth && { width: `${descriptionWidth}px` }}>
          {variantName ? variantsDescriptions[variantName] : variantsDescriptions[index]}
        </div>
      </div>
    )
  })

  const scroll = maxVariants && (maxVariants < variants.length)
  return (
    <li
      class="option--selector o-flex-column"
      onMousedown={parent.onMouseDownHandler}
      data-caption={name}
      {...{ directives }}
    >
      {
        scroll
          ? (
            <div class="option--selector__scroll-wrapper" style={{ height: `${maxVariants * 26}px` }}>
              <scrollable-area appearance="blurry">
                {variantNodes}
              </scrollable-area>
            </div>
          )
          : variantNodes
      }
    </li>
  )
}

export default {
  name: 'PopupMenuOption',
  functional: true,
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  render (h, context) {
    const { props, parent } = context

    const { option } = props

    const { action, fakeLink } = option

    const isLink = typeof action === 'string' || fakeLink
    if (isLink) return renderLinkOption(h, option, parent)

    const { active, divider, icon, key, name, variants, dataCy } = option

    const directives = [
      { name: 'testing', value: { key } },
    ]

    if (variants !== undefined) return renderPickerOption(h, { option, parent, directives })
    if (active !== undefined) return renderToggleOption(h, { option, parent, directives })

    const iconRender = (icon && icon.render) ? icon.render(h, context) : null
    const iconWrapper = iconRender ? (<div class="icon o-flex-row">{iconRender}</div>) : null

    const className = iconWrapper ? 'option o-flex-row' : (!divider ? 'option' : 'divider')

    const handler = e => {
      e.stopPropagation()
      action && action()
      parent.closeWindow()
    }

    return (
      <li
        class={className}
        onMousedown={parent.onMouseDownHandler}
        onClick={handler}
        data-content={name}
        {...{ directives }}
        data-cy={dataCy}
      >
        { iconWrapper }
        { name }
      </li>
    )
  },
}
