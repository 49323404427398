//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { date, format } from 'quasar'
import { mapGetters } from 'vuex'
import { preventHtmlTags, highlightMatches, isMatch } from '@/utils'

import { teamsStore, uiStore } from '@/store'
import router from '@/router'

const { humanStorageSize } = format
const { getDateDiff } = date

export default {
  name: 'FileItem',
  components: {
    IconArrowThin: () => import('@/components/UI/icons/IconArrowThin'),
    IconEllipsis: () => import('@/components/UI/icons/IconEllipsis'),
    IconFile: () => import('@/components/UI/icons/IconFile'),
    IconImage: () => import('@/components/UI/icons/IconImage'),
    IconMic: () => import('@/components/UI/icons/IconMic'),
    IconPlay: () => import('@/components/UI/icons/IconPlay'),
  },
  props: ['data', 'filter'],
  data () {
    return {
      noPreview: false,
    }
  },
  created () {
    if (this.preview) {
      this.handlePreviewLoadingError = () => { this.noPreview = true }
    }

    let { name } = this.content
    name = name || ''

    const index = name.lastIndexOf('.')
    this.ext = (index < 0 || index === name.length - 1)
      ? this.content.type
      : name.substr(index + 1)
    if (this.content.name) {
      this.name = this.content.name
    } else {
      this.$sentry.withScope(scope => {
        scope.setExtra('content', JSON.stringify(this.content))
        this.$sentry.captureMessage('File content without name')
      })
      this.name = this.content.text
    }
  },
  mounted () {
    if (this.type === 'image' || this.type === 'video') {
      const { icon } = this.$refs
      icon && icon.addEventListener('click', event => {
        event.preventDefault()

        uiStore.actions.showModal({ instance: 'FileViewer', payload: this.content })
        return false
      })
    }
  },
  computed: {
    ...mapGetters([
      'chatName',
      'getUserId',
      'entityExists',
    ]),
    icon () {
      switch (this.type) {
        case 'image':
          return 'icon-image'
        case 'video':
          return 'icon-play'
        case 'audiomsg':
          return 'icon-mic'
        case 'file':
        default:
          return 'icon-file'
      }
    },
    options () {
      const download = {
        name: this.$t('modals.FileBrowser.download'),
        action: () => this.$refs.download?.click(),
      }
      const forward = {
        name: this.$t('modals.FileBrowser.forward'),
        action: () => uiStore.actions.showModal({
          instance: 'RedirectMessagesModal',
          payload: this.data,
        }),
      }
      return teamsStore.getters.isSingleGroupTeam()
        ? [download]
        : [download, forward]
    },
    content () {
      if (!this.data) return {}

      const { content } = this.data
      return content || {}
    },
    type () {
      const { type, previewURL, animated } = this.content
      switch (type) {
        case 'image': {
          if (!previewURL) break

          return animated ? 'video' : 'image'
        }
        case 'video': {
          if (!previewURL) break

          return 'video'
        }
        case 'audiomsg': return 'audiomsg'
      }
      return 'file'
    },
    size () {
      const { size } = this.content
      return size ? humanStorageSize(size) : 'Unknown'
    },
    date () {
      const created = new Date(this.data.created)
      const now = new Date()
      let format = {}
      let prefix = null

      if (getDateDiff(now, created, 'days') < 1) {
        format = { timeStyle: 'short' }
        prefix = this.$t('common.today')
      } else if (getDateDiff(now, created, 'years') > 1) {
        format = { dateStyle: 'medium' }
      } else {
        format = {
          hour: 'numeric',
          minute: 'numeric',
          month: 'short',
          day: 'numeric',
        }
      }

      const lang = this.$i18n.locale
      const time = new Intl.DateTimeFormat(lang, format).format(created)
      return prefix ? `${prefix}, ${time}` : time
    },
    preview () { return this.noPreview ? null : this.content.previewURL ?? null },
    isFromMe () {
      return this.getUserId === this.data.sender
    },
    isToMe () {
      return this.data.sender === this.data.chatId
    },
    sender () {
      return this.isFromMe
        ? this.$t('modals.FileBrowser.fromMe')
        : this.chatName(this.data.sender)
    },
    recipient () {
      return this.isToMe
        ? this.$t('modals.FileBrowser.toMe')
        : this.chatName(this.data.chatId)
    },
  },
  methods: {
    openChat (chatId) {
      if (this.getUserId === chatId) return

      if (chatId === this.data.chatId) {
        router.push({
          name: 'Chat',
          params: {
            teamId: teamsStore.getters.currentTeam.uid,
            jid: chatId,
          },
          query: {
            message: this.data.messageId,
          },
        })
        ChatEventBus.$emit(Events.REINIT_CHAT)
        return
      }
      router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: chatId,
        },
      })
      uiStore.actions.openChat()
    },
    highlightFilterMatches (value) {
      const sanitized = preventHtmlTags(value)

      if (!this.filter || !isMatch(value, this.filter)) return sanitized
      return highlightMatches(sanitized, this.filter)
    },
  },
}
