import { transformEntityName } from '@/utils'
import { Chat } from '@tada-team/tdproto-ts'
import { groupsStore } from '@/store'

interface Item {
  name: string;
  value: string;
  isGroup: boolean;
  icon: string | null;
  normal: boolean;
}

export const entityTransform = (entity: TADA.Contact | Chat): Item => {
  const isGroup = entity instanceof Chat && entity.chatType === 'group'
  const result = {
    name: entity.displayName,
    value: entity.jid,
    isGroup: isGroup,
    icon: isGroup
      ? (entity instanceof Chat && entity.isPublic
        ? 'public-group-icon'
        : 'default-group-icon')
      : null,
    normal: !(entity as TADA.Contact).isArchive,
    avatar: entity instanceof Chat
      ? groupsStore.getters.icon(entity.jid)
      : entity.icon,
  }
  return result
}

export const transformName = (value: string): string => {
  if (!value) return 'Empty'
  return transformEntityName(value)
}

export const isItemInList = (items: Array<Item>, target: Item) => {
  return items.some(item => {
    return item.value === target.value
  })
}
