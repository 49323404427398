








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'quasar'
import { Team } from '@tada-team/tdproto-ts'
import { teamsStore, uiStore } from '@/store'
import { transformEntityName } from '@/utils'
import IconTimes from '@/components/UI/icons/IconTimes.vue'

const { capitalize } = format

@Component({
  components: {
    IconTimes,
    IconSettings: () => import('@/components/UI/icons/IconSettings.vue'),
  },
})
export default class TeamProfileHeader extends Vue {
  @Prop({
    type: Team,
    required: true,
  }) readonly team!: Team

  get headerCaption () {
    const c = teamsStore.getters.isSingleGroupTeam(this.team.uid)
      ? this.$t('profiles.singleGroupTeamProfile')
      : this.$t('profiles.teamProfile')
    return capitalize(c.toString())
  }

  get isCurrentTeam (): boolean {
    return this.team.uid === teamsStore.state.currentTeamId
  }

  get hasRights () {
    const { status } = this.team.me
    return ['admin', 'owner'].includes(status)
  }

  openSettings (): void {
    if (!this.isCurrentTeam || !this.hasRights) return
    uiStore.actions.showModal({
      instance: 'TeamSettingsModal',
      payload: this.team.uid,
    })
  }

  close (): void {
    uiStore.actions.switchRightBarInstance()
  }

  transformName (name: string): string {
    return transformEntityName(name || '')
  }
}
