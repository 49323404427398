//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { isItemInList } from './Utils.ts'
import ListMixin from './ListMixin'

export default {
  name: 'FileTypeList',
  mixins: [ListMixin],
  components: {
    'mark-icon': createSVGComponent({ icon: 'mark_filled', size: 16, permanentlyActive: true }),
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    list () {
      return this.items.filter(item => {
        return !isItemInList(this.value, item)
      })
    },
  },
  created () {
    this.items = [{
      name: this.$t('modals.FileBrowser.typeImage'),
      icon: createSVGComponent({ icon: 'pic', size: 20 }),
      value: 'image',
    },
    {
      name: this.$t('modals.FileBrowser.typeVideo'),
      icon: createSVGComponent({ icon: 'video', size: 20 }),
      value: 'video',
    },
    {
      name: this.$t('modals.FileBrowser.typeFile'),
      icon: createSVGComponent({ icon: 'file', size: 20 }),
      value: 'file',
    },
    {
      name: this.$t('modals.FileBrowser.typeVoice'),
      icon: createSVGComponent({ icon: 'audiomsg', size: 20 }),
      value: 'audiomsg',
    }]
  },
}
