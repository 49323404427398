//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { multiSearch } from '@/utils/Common.ts'

import DragBufferMixin from './DragBufferMixin'
import ContactCard from './ContactCard'

import { isGUID, isMatch } from '@/utils'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

import {
  sectionsStore,
  contactsStore,
  teamsStore,
  uiStore,
} from '@/store'
import { UPDATE_CONTACT } from '@/store/actionTypes'

const contactSectionSettings = 'contactSectionSettings'

export default {
  name: 'ContactsSection',
  mixins: [DragBufferMixin],
  components: {
    'contacts-section-menu': () => import('./ContactsSectionMenu'),
    expand: createSVGComponent({ icon: 'expand', size: 20 }),
    more: createSVGComponent({ icon: 'more', size: 20 }),

    ContactCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    hotspot: {
      type: String,
      default: 'none',
    },
    collapsedAtBeginning: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isCollapsed: this.collapsedAtBeginning,

      isDragOver: false,
      isDragged: false,

      isSectionDragOver: '',
      showArchivedContacts: false,
    }
  },
  created () {
    this.dragCounter = 0

    const tempData = localStorage && localStorage.getItem(contactSectionSettings)
    if (tempData) {
      const data = JSON.parse(tempData)

      if (!data[this.data.uid]) {
        this.isCollapsed = true // if we have data in localStorage, we should rewrite collapsedAtBeginning

        return
      }

      this.isCollapsed = data[this.data.uid].isCollapsed
      this.showArchivedContacts = data[this.data.uid].showArchivedContacts
    }
  },
  computed: {
    showContactsSectionsRelated () {
      return uiStore.getters.showContactsSectionsRelated
    },
    contacts () {
      let contacts = sectionsStore.getters.contactsBySection(this.data.uid) || []

      if (!this.showArchivedContacts) {
        contacts = contacts.filter(contact => !contact.isArchive)
      }
      return contacts.sort((a, b) => a.displayName.localeCompare(b.displayName))
    },
    filteredContacts () {
      if (!this.filter) return this.contacts
      const matchFields = ['displayName', 'role']
      const matchFn = searchString => {
        return c => matchFields.some(field => isMatch(c[field], searchString))
      }
      // find contacts by changing the keyboard layout
      return multiSearch(searchString => this.contacts.filter(matchFn(searchString)), this.filter, 'jid', true)
    },
    noFilterResults () {
      return this.filter && this.filteredContacts.length <= 0
    },
    canDragItems () {
      return !this.filter
    },
    canDrag () {
      if (!teamsStore.getters.currentTeam.me.canManageSections) return false

      return !this.filter && this.data.uid
    },
  },
  methods: {
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed

      this.syncLocalStorage()
    },
    toggleArchivedContacts (val) {
      this.showArchivedContacts = val

      this.syncLocalStorage()
    },
    syncLocalStorage () {
      const tempData = localStorage && localStorage.getItem(contactSectionSettings)
      let data = {}

      if (tempData) {
        data = JSON.parse(tempData)
      }

      if (!this.isCollapsed || this.showArchivedContacts) {
        data[this.data.uid] = {
          isCollapsed: this.isCollapsed,
          showArchivedContacts: this.showArchivedContacts,
        }
      } else {
        delete data[this.data.uid]
      }

      localStorage && localStorage.setItem(contactSectionSettings, JSON.stringify(data))
    },
    handleDragStart (event) {
      event.dataTransfer.setData('application/x-dnd', 'payload')
      this.dragBuffer.setupData('section', {
        index: this.index,
        uid: this.data.uid,
      })
      this.isDragged = true
    },
    handleDragEnd () {
      this.isDragged = false

      this.dragBuffer.clear()

      this.$emit('dragend', this.data.uid)
    },
    handleDragEnter (event) {
      event.preventDefault()

      if (!this.dragBuffer.isValid(event)) return

      const data = this.dragBuffer.getDragData()
      if (!data) return

      const { section: contactSection } = data
      if (!isGUID(contactSection)) return

      this.dragCounter += 1

      if (this.dragBuffer.getDragType() === 'contact') {
        this.isDragOver = contactSection !== this.data.uid
      }
    },
    handleDragLeave (event) {
      if (!this.dragBuffer.isValid(event)) return

      const data = this.dragBuffer.getDragData()
      if (!data) return

      const { section: contactSection } = data
      if (!isGUID(contactSection)) return

      this.dragCounter -= 1
      if (this.dragCounter === 0) {
        this.isDragOver = false
      }
      this.isSectionDragOver = ''
    },
    async handleDrop (event) {
      if (!this.dragBuffer.isValid(event)) return

      const data = this.dragBuffer.getDragData()
      if (!data) return

      const { section: contactSection, jid: contactId } = data
      if (contactSection === this.data.uid) return

      this.isDragOver = false
      this.dragCounter = 0
      this.isSectionDragOver = ''

      const { dispatch } = this.$store
      const contact = contactsStore.getters.contact(contactId)

      if (!contact || contact.section === this.data.uid) return

      this.dragBuffer.clear()

      const { section: preSection } = contact
      const newContact = { ...contact, ...{ section: this.data.uid } }
      dispatch(UPDATE_CONTACT, { contact: newContact })
      contactsStore.actions.updateContact(newContact)
      teamsStore.actions.updateContactInCurrentTeam(newContact)
      try {
        const data = { section: this.data.uid }
        const contact = await api.contacts.edit(contactId, data)

        dispatch(UPDATE_CONTACT, { contact })
        contactsStore.actions.updateContact(contact)
        teamsStore.actions.updateContactInCurrentTeam(contact)
      } catch (e) {
        const preSectionContact = { ...contact, ...{ section: preSection } }
        dispatch(UPDATE_CONTACT, { contact: preSectionContact })
        contactsStore.actions.updateContact(preSectionContact)
        teamsStore.actions.updateContactInCurrentTeam(preSectionContact)
      }
    },
  },
}
