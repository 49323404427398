//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import OnlineStatus from '@/components/Chat/OnlineStatus'
import { Events, TaskEventBus } from '@/components/Tasks/EventBus'
import ContactStatusBadges from '@/components/UI/ContactStatusBadges.vue'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import ScrollableArea from '@/components/UI/ScrollableArea'
import PopupMenuWrapper from '@/components/UI/Wrappers/PopupMenuWrapper'
import Notifier from '@/electron/Notifier'
import { changeLanguage } from '@/i18n'
import { defaultLogger } from '@/loggers'
import { isPushSupported } from '@/notifications/Utils'
import {
  activeCallStore,
  callsStore,
  contactsStore,
  groupsStore,
  nodesStore,
  teamsStore,
  uiSettingsStore,
  uiStore,
} from '@/store'
import ThemeManager from '@/themes'
import { Contact } from '@tada-team/tdproto-ts'
import { format } from 'quasar'
import { mapGetters } from 'vuex'
import ProfileAvatar from '../Avatar'
import CommonMixin from '../CommonMixin'
import Description from '../Description'
import GroupInstanceRow from './GroupInstanceRow'
import StatusProfile from './StatusProfile'

const { capitalize } = format
const HIDDEN_COUNT_CLICK = 10

export default {
  name: 'ContactProfile',
  mixins: [CommonMixin],
  props: {
    contactProfile: {
      required: false,
      type: Contact,
    },
  },
  components: {
    ContactStatusBadges,
    'scrollable-area': ScrollableArea,
    'menu-icon': createSVGComponent({ icon: 'more', size: 20 }),
    'edit-icon': createSVGComponent({ icon: 'edit', size: 20 }),
    'close-icon': createSVGComponent({ icon: 'close', size: 20 }),
    // IconQuestionCircle: () => import('@/components/UI/icons/IconQuestionCircle'),
    'popup-menu': PopupMenuWrapper,
    'mark-icon': createSVGComponent({
      icon: 'mark-only',
      size: 16,
      permanentlyActive: true,
    }),
    IconChevronRight: () =>
      import('@/components/UI/icons/IconChevronRight.vue'),
    OnlineStatus,
    description: Description,
    ProfileAvatar,
    StatusProfile,
    /**
     * @see tfaModalType below
     */
    // ! rename
    CreateTFA: () =>
      import('@/components/Modals/Default/TwoFactorAuth/CreatePassword.vue'),
    RemoveTFA: () =>
      import('@/components/Modals/Default/TwoFactorAuth/RemovePassword.vue'),
    ChangePassTFA: () =>
      import('@/components/Modals/Default/TwoFactorAuth/ChangePassword.vue'),
    ChangeEmailTFA: () =>
      import('@/components/Modals/Default/TwoFactorAuth/ChangeEmail.vue'),
    Notifications: () => import('./Notifications.vue'),
  },
  data () {
    return {
      hiddenClicksCount: 0,
      clearingSettings: false,
      desktopNotificationAPI: '',
      isPushSupported: false,
      language: null,
      selectedLanguage: null,
      theme: '',
      desktopAutodownloadFiles: false,
      autolaunch: false,
      hiddenLaunch: false,
      isEnabledTFA: false,
      tfaModalType: null, // * CreateTFA | RemoveTFA | ChangePassTFA | ChangeEmailTFA | null
    }
  },
  created () {
    this.isDev = window.FEATURES.is_testing

    this.isEnabledTFA = window.FEATURES.auth_2fa ?? false

    this.themes = ThemeManager.displayedThemes
    const currentTheme = ThemeManager.getCurrentThemeName()
    this.theme = currentTheme

    this.language = uiSettingsStore.getters.language || 'ru'
    this.selectedLanguage = this.language

    this.desktopAutodownloadFiles =
      uiSettingsStore.getters.desktopAutodownloadFiles || false

    this.desktopNotificationAPI =
      Notifier.getCurrentType() || this.desktopAvailableNotificationTypes[0]

    this.isPushSupported = isPushSupported()

    this.autolaunch =
      this.$q.platform.is.electron &&
      window._electronFeatures.includes('openAtLogin')

    this.hiddenLaunch =
      this.$q.platform.is.electron &&
      window._electronFeatures.includes('openAsHidden')

    this.unwatchFn = this.$watch(
      () => {
        return this.currentChat
      },
      chatId => {
        // const isMe = chatId === getters.getUserId

        if (this.chatType(chatId) !== 'direct' || chatId === this.contact.jid) {
          return
        }

        uiStore.actions.switchRightBarInstance({
          instance: 'contact-profile',
          payload: chatId,
        })
      },
    )
  },
  beforeDestroy () {
    this.unwatchFn && this.unwatchFn()
  },
  computed: {
    ...mapGetters([
      'chatType',
      'currentChat',
      'getUserId',
      'isActiveTFA',
    ]),
    debugLevel () {
      return uiSettingsStore.getters.debugLevel
    },
    currentRightBarPayload () {
      return uiStore.getters.currentRightBarPayload
    },
    groupList () {
      return groupsStore.getters.list
    },
    node () {
      const cn = this.contact.node
      if (!cn) return
      return nodesStore.state.nodes.find(n => n.uid === cn)
    },
    desktopAvailableNotificationTypes () {
      return Notifier.getAvailableTypes()
    },
    // если нужна будет подсказка для винды и автозагрузки
    // winToolTip () {
    //   return this.$t(
    //     'profiles.autoLaunch.tooltip',
    //     { title: window.FEATURES.app_title ?? 'tada.team' }
    //   )
    // },
    altSendOption () {
      const key = this.$q.platform.is.mac
        ? this.$t('common.commandKey')
        : this.$t('common.controlKey')
      const label = this.$t('profiles.altSendOption.title', { key })
      const caption = this.$t('profiles.altSendOption.caption', { key })
      const value = this.contact.altSend
      const action = bool =>
        contactsStore.actions.editContact({
          jid: this.getUserId,
          params: { alt_send: bool },
        })
      return {
        label,
        caption,
        value,
        action,
      }
    },
    // teamSpecificSettingsCaption () {
    //   return this.isSingleGroupTeam()
    //     ? this.$t('common.singleGroupTeamSettings')
    //     : this.$t('common.teamSettings')
    // },
    langInStore () {
      return uiSettingsStore.getters.language
    },
    canEditContact () {
      let fields = []
      if (this.isMyProfile) {
        fields = [
          'contact_phone',
          'contact_email',
          'family_name',
          'given_name',
          'patronymic',
          'icons',
          'role',
        ]
      } else {
        fields = ['sections', 'status', 'role']
      }
      return fields.some(field =>
        this.contact.changeableFields?.includes(field),
      )
    },
    contact () {
      const { getters } = this.$store
      const contact = this.contactProfile ||
        contactsStore.getters.contact(this.currentRightBarPayload) ||
        getters.profile

      // замена роли на более приоретитную (кастом филдс = главные)
      if (contact.customFields?.title) {
        contact.role = contact.customFields.title
      }

      return contact
    },
    isMyProfile () {
      return this.getUserId === this.contact.jid
    },
    settingsModal () {
      return {
        instance: this.isMyProfile ? 'EditProfile' : 'EditContact',
        payload: this.contact.jid,
      }
    },
    // TODO: refactor - same is used in TaskList
    // ? create a proper getter?
    displayUnreadFirst: {
      get () {
        return (
          this.$store.getters.unreadFirst
        )
      },
      set (bool) {
        contactsStore.actions.editContact({
          jid: this.contact.jid,
          params: { unread_first: bool },
        })
      },
    },
    useTeamAvatarForFavicon () {
      return this.globalSetting('useTeamAvatarForFavicon')
    },
    showThemePicker () {
      return !teamsStore.getters.currentTeam.theme
    },
    taskText () {
      return capitalize(this.$t('glossary.task'))
    },
    canCall () {
      return this.contact.canCall && callsStore.getters.isCallingEnabled()
    },
    showHiddenClearing () {
      return this.hiddenClicksCount >= HIDDEN_COUNT_CLICK
    },
  },
  methods: {
    onClickHidden () {
      if (!this.isDev && !this.showHiddenClearing) this.hiddenClicksCount += 1
    },
    globalSetting (key) {
      return uiSettingsStore.getters.globalSetting(key)
    },
    /**
     * Open modal for TFA base on  type: create or remove
     * @see tfaModalType above
     */
    setActiveTFAModal (value) {
      this.tfaModalType = value ? 'CreateTFA' : 'RemoveTFA'
    },
    isSingleGroupTeam () {
      return teamsStore.getters.isSingleGroupTeam()
    },
    async clearTeamSettings () {
      this.clearingSettings = true
      await uiSettingsStore.actions.clearTeamSettings()
      this.clearingSettings = false
    },
    clearAllSettings () {
      uiSettingsStore.actions.clearAllSettings()
    },
    options () {
      const result = [
        {
          name: this.$t('profiles.lookupFiles'),
          action: () => {
            uiStore.actions.switchRightBarInstance({
              instance: 'file-browser',
              payload: { chatId: this.contact.jid },
            })
          },
        },
      ]

      if (this.contact.canAddToGroup) {
        result.push({
          name: this.$t('profiles.addToGroup'),
          action: this.addToGroups,
        })
      }

      return result
    },
    setTheme (value) {
      ThemeManager.setTheme(value)
      this.theme = value
    },
    async setLanguage (lang) {
      this.language = lang
      await changeLanguage(lang)
      window.location.reload()
    },
    setDesktopNotificationAPI (value) {
      Notifier.changeNotificationAPI(value)
      this.desktopNotificationAPI =
        Notifier.getCurrentType() || this.desktopAvailableNotificationTypes[0]
    },
    setDesktopAutodownloadFiles (value) {
      uiSettingsStore.actions.setDesktopAutodownloadFiles(value)
      this.desktopAutodownloadFiles = value
    },
    async changeAsteriskMentionOption (bool) {
      contactsStore.actions.editContact({
        jid: this.contact.jid,
        params: { asterisk_mention: bool },
      })
    },
    addToGroups () {
      if (!this.contact.canAddToGroup) return

      const payload = {
        getData: async () => {
          const list = await api.groups.getGroupsForInviting(this.contact.jid)
          return list
        },
        componentRow: GroupInstanceRow,
        componentRowProps: { contactJid: this.contact.jid },

        caption: this.$t('profiles.addToGroups.caption', {
          nameHTML: `<span style="font-weight: 600">${this.transformString(
            this.contact.displayName,
          )}</span>`,
        }),
        searchPlaceholder: this.$t('profiles.addToGroups.searchPlaceholder'),
        searchEmptyMessage: this.$t('profiles.addToGroups.searchEmptyMessage'),
      }

      uiStore.actions.showModal({
        instance: 'EntityGenericList',
        payload,
      })
    },
    write () {
      // if (!this.contact.canSendMessage) return
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: this.contact.jid,
        },
      })
    },
    talk () {
      if (!this.canCall) return
      activeCallStore.actions.startCall({
        jid: this.contact.jid,
        startBuzzing: true,
        type: callsStore.getters.preferredCallType,
      })
    },
    task () {
      if (!this.contact.canCreateTask) return

      uiStore.actions.showModal({
        instance: 'new-task',
        payload: { assignee: this.contact.jid },
      })
    },
    showTasks () {
      window.goal('userProfileSettings', {
        userProfileSettings: 'Показать задачи участника',
      })
      TaskEventBus.$emit(Events.SET_ASSIGNEE_FILTER, [this.contact.jid])
      // uiStore.actions.updateRightBarTasksSortFilters({
      //   key: 'assignees',
      //   value: [this.contact.jid],
      // })
      uiStore.actions.switchRightBarInstance({
        instance: 'tasks',
        // payload: { chatId: this.contact.jid },
      })
    },
    openCalendar () {
      window.goal('userProfileSettings', {
        userProfileSettings: 'Показать календарь участника',
      })

      this.$router.push({
        name: 'Calendar',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          contactId: this.contact.jid,
        },
      })

      this.close()
    },
    async kick () {
      if (!this.contact.canDelete) return
      const icon = contactsStore.getters.contactIcon(this.contact.jid)
      const dialogOptions = {
        caption: this.$t('profiles.kick.caption'),
        icon,
        content: this.$t('profiles.kick.content'),
        formatData: { name: this.contact.displayName },
        acceptCallback: async () => {
          await api.contacts.delete(this.contact.jid)
        },
        acceptLabel: this.$t('profiles.kick.acceptLabel'),
        acceptError: this.$t('profiles.kick.acceptError'),
      }

      uiStore.actions.showModal({
        instance: 'Dialog',
        payload: dialogOptions,
      })
    },
    formatPhone (phone) {
      phone = phone || ''
      phone = phone.replace(/ /g, '')
      if (phone.length === 0) return this.$t('profiles.notSpecified')
      else if (phone.length !== 12) return phone

      let result = phone.substring(0, 2)
      result += ' ' + phone.substring(2, 5)
      result += ' ' + phone.substring(5, 8)
      result += '-' + phone.substring(8, 10)
      result += '-' + phone.substring(10, 12)
      return result
    },
    updateUseTeamAvatarForFaviconSetting (bool) {
      uiSettingsStore.actions.setGlobalSettings({ useTeamAvatarForFavicon: bool })
      window.f.setDefaultFavicon()
    },
    changeAutoLaunch (value) {
      this.autolaunch = value
      window.goal('userProfileSettings',
        { userProfileSettings: `Автозапуск приложения — ${value ? 'включен' : 'выключен'}` })
      this.changeElectronLaunchSettings('openAtLogin', value)
    },
    changeHiddenLaunch (value) {
      this.hiddenLaunch = value
      window.goal('userProfileSettings',
        { userProfileSettings: `Запуск приложения свёрнутым — ${value ? 'включен' : 'выключен'}` })
      this.changeElectronLaunchSettings('openAsHidden', value)
    },
    changeElectronLaunchSettings (key, value) {
      if (!window._ipc) return
      const launchValue = {}
      launchValue[key] = value
      window._ipc.send('launch-on-startup', { value: launchValue })
      value
        ? window._electronFeatures.push(key)
        : (window._electronFeatures = window._electronFeatures.filter(
          e => e !== key,
        ))
    },
    updateDebugLevel (levelNumber) {
      uiSettingsStore.actions.setDebuglevel(levelNumber)

      console.log('_________________')
      console.log(`[LEVEL] ${defaultLogger.getLevel()} from 5 (5=None)`)
      defaultLogger.trace('[TEST] trace')
      defaultLogger.debug('[TEST] debug')
      defaultLogger.info('[TEST] info')
      defaultLogger.warn('[TEST] warn')
      defaultLogger.error('[TEST] error')
      console.log('_________________')
    },
  },
}
