import { render, staticRenderFns } from "./TasksTabs.vue?vue&type=template&id=83bcda74&scoped=true&"
import script from "./TasksTabs.vue?vue&type=script&lang=js&"
export * from "./TasksTabs.vue?vue&type=script&lang=js&"
import style0 from "./TasksTabs.vue?vue&type=style&index=0&id=83bcda74&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83bcda74",
  null
  
)

export default component.exports
import {QBtnDropdown,QChip,QIcon,QList,QItem,QItemSection,QItemLabel,QSeparator,QBadge,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtnDropdown,QChip,QIcon,QList,QItem,QItemSection,QItemLabel,QSeparator,QBadge,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
