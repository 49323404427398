//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PopupDropdownMixin from './PopupDropdownMixin'

import MenuOption from './MenuOption.jsx'

export default {
  name: 'UiPopupMenuWrapper',
  components: {
    'menu-option': MenuOption,
  },
  mixins: [PopupDropdownMixin],
  props: {
    options: {
      type: [Array, Function],
      required: true,
    },
    centering: Boolean,
    maxHeight: String,
  },
  data () {
    return {
      ready: true,
      optionList: typeof this.options !== 'function' ? this.options : null,
    }
  },
  created () {
    if (this.optionList) return

    if (this.centering) {
      this.ready = false
    }

    this.$watch(vm => vm.open, open => {
      this.optionList = open ? this.options() : null

      if (open) {
        this.centering && this.$nextTick(this.centeringMenuPotision)
        this.autoFix && this.$nextTick(this.fixMenuPosition)
      }
    })
  },
  methods: {
    closeWindow () {
      this.open = false

      const { trigger } = this.$refs
      trigger && trigger.blur()
    },
    onMouseDownHandler (e) {
      e.preventDefault()
      return false
    },
  },
  computed: {
    menuStyle () {
      const style = this.alignStyle
      if (this.$props.maxHeight) style['max-height'] = this.$props.maxHeight
      return style
    },
  },
}
