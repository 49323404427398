//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { Chat } from '@tada-team/tdproto-ts'

export default {
  name: 'ProfileDescription',
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      value: '',
      isInEditState: false,
      isInProgress: false,
      error: '',
    }
  },
  computed: {
    hasRights () {
      if (this.isGroup) {
        return this.entity.canChangeSettings
      }
      return this.entity.changeableFields?.includes('role')
    },
    isGroup () {
      return this.entity instanceof Chat
    },
    content () {
      return this.entity[this.field]
    },
    isVisible () {
      return this.hasRights || !!this.content
    },
    descriptionChanged () {
      return this.value !== this.content
    },
    isActive () {
      return this.descriptionChanged || this.isInEditState
    },
    field () {
      return this.isGroup ? 'description' : 'role'
    },
  },
  watch: {
    entity (value) {
      this.clearValue((value || {})[this.field])
    },
    value () {
      this.error && (this.error = '')
    },
  },
  created () {
    this.clearValue()
  },
  methods: {
    async accept () {
      if (!this.descriptionChanged) return

      this.isInProgress = true
      this.toggleEditState(false)

      const data = {}
      data[this.field] = this.value ? this.value.substr(0, 500) : ''

      try {
        if (this.isGroup) {
          await api.groups.edit(this.entity.jid, data)
        } else {
          await api.contacts.edit(this.entity.jid, data)
        }
      } catch (e) {
        let { error, details } = e
        details && Object.keys(details).some(key => {
          return (error = details[key])
        })
        this.error = error || this.$t('errors.implicitError')
      } finally {
        this.isInProgress = false

        this.error && this.handleClick()
      }
    },
    clearValue (value) {
      this.value = value === undefined ? this.content : value
    },
    handleClick () {
      this.toggleEditState(true)
      this.$nextTick(this.focus)
    },
    toggleEditState (value) {
      this.isInEditState = value !== undefined ? value : !this.isInEditState

      if (!this.isInEditState) {
        this.error = ''
      }
    },
    focus () {
      const { input } = this.$refs
      input && input.focus()
    },
  },
}
