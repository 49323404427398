//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PopoutWrapper from '@/components/UI/Popouts/PopoutComponent.vue'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import FilterBarItem from './FilterBarItem'
import ListMixin from './Lists/ListMixin'
import { debounce } from 'quasar'

export default {
  name: 'FilterBar',
  mixins: [ListMixin],
  components: {
    'popup-wrapper': PopoutWrapper,
    'expand-icon': createSVGComponent({ icon: 'expand', size: 16, sizeStorage: 20 }),

    FilterBarItem,
  },
  props: {
    popoutId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    inputPrefix: {
      type: String,
      default: '',
    },
    showAvatars: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    isEmpty () {
      return !this.value || this.value.length === 0
    },
  },
  watch: {
    value () {
      this.$nextTick(() => {
        const { popup } = this.$refs
        popup && popup.updatePosition()
      })
    },
    isOpen (value) {
      if (!value) {
        this.changeFilter && this.changeFilter(null)
        return
      }

      this.$nextTick(() => {
        const { input } = this.$refs
        input && input.focus({ preventScroll: true })

        this.$emit('open', value)

        const { popup } = this.$refs
        popup && popup.updatePosition()
      })
    },
  },
  created () {
    if (this.inputPrefix) {
      this.changeFilter = event => {
        if (!event) {
          this.$emit('filterChanged', '')
          return
        }

        const { target } = event
        if (!target) return

        const { value } = target
        this.$emit('filterChanged', value || '')
      }

      this.debouncedChangeFilter = debounce(this.changeFilter, 150)

      // Hack: Прокручиваем скролл вниз по фокусу
      // this.handleFocus = () => {
      //   this.$nextTick(() => {
      //     const { popup } = this.$refs
      //     popup && popup.updatePosition()
      //   })
      // }
    }

    this.appearance = {
      marginFromAnchor: 5,
      minMarginFromBounds: 6,
    }
  },
  beforeDestroy () {
    this.debouncedChangeFilter && this.debouncedChangeFilter.cancel()
  },
  methods: {
    clearInput () {
      const { input } = this.$refs
      input && (input.value = '')
    },
  },
}
