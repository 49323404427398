import { render, staticRenderFns } from "./TaskListRefactor.vue?vue&type=template&id=6f7a927a&scoped=true&"
import script from "./TaskListRefactor.vue?vue&type=script&lang=js&"
export * from "./TaskListRefactor.vue?vue&type=script&lang=js&"
import style0 from "./TaskListRefactor.vue?vue&type=style&index=0&id=6f7a927a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7a927a",
  null
  
)

export default component.exports
import {QIntersection,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIntersection})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
