//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './Profiles/styles.scss'

import Tasks from '@/components/Tasks/'
import TeamProfile from './Profiles/TeamProfile'
import ContactProfile from './Profiles/ContactProfile'
import GroupProfile from './Profiles/GroupProfile'
import FileBrowser from './FileBrowser/'
import Contacts from './Contacts/'
import Meetings from './Meetings/index.vue'
import {
  teamsStore,
  uiStore,
} from '@/store'

export default {
  name: 'RightBar',
  components: {
    tasks: Tasks,
    meetings: Meetings,
    contacts: Contacts,
    'team-profile': TeamProfile,
    'contact-profile': ContactProfile,
    'group-profile': GroupProfile,
    'file-browser': FileBrowser,
  },
  computed: {
    currentInstance () {
      const instance = uiStore.getters.currentRightBarInstance

      return instance
    },
    currentPrimaryInstance () {
      const instance = uiStore.getters.currentRightBarPrimaryInstance

      return instance
    },
    currentTeamId () {
      return teamsStore.state.currentTeamId
    },
  },
  created () { },
  methods: {
    reset () {
      uiStore.actions.switchRightBarInstance()
    },
  },
}
