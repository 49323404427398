








































import i18n from '@/i18n'
import api from '@/api/v3'
import { Contact, PublicStatus } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TdDropdown, TdBtn, DropdownItem } from 'td-ui'
import EmojiDropdown from './EmojiDropdown.vue'
import store from '@/store'

@Component({
  components: {
    TdBtn,
    TdDropdown,
    EmojiDropdown,
  },
})
export default class StatusProfile extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly entity!: Contact

  items: DropdownItem[] = []

  get isMyProfile () {
    return store.getters.getUserId === this.entity.jid
  }

  get lang () {
    return i18n.locale
  }

  mounted () {
    this.initStatuses()
  }

  async initStatuses () {
    this.items = (await api.getListPublicStatuses()).map((item: PublicStatus) => ({
      type: item.type,
      label: this.lang === 'ru' ? item.statusRu : item.statusEn,
      icon: EmojiDropdown,
      iconProps: { emoji: item.emoji },
      action: () => {
        api.contacts.edit(this.entity.jid, {
          public_status: item.type,
        })
      },
    }))
  }
}

