//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { contactsStore, draftsStore, teamsStore, uiStore } from '@/store'
import { logout } from '@/utils'
import api from '@/api/v3'
import IconUserPlus from '@/components/UI/icons/IconUserPlus.vue'
import ProfileAvatar from '../Avatar'
import ScrollableArea from '@/components/UI/ScrollableArea'
import TeamProfileHeader from './TeamProfileHeader.vue'
import { defaultLogger } from '@/loggers'

import { mapGetters } from 'vuex'

export default {
  name: 'TeamProfile',
  components: {
    'scrollable-area': ScrollableArea,
    IconUserPlus,
    TeamProfileHeader,
    ProfileAvatar,
  },
  data () {
    return {
      owner: null,
      contacts: null,
      usage: null,
    }
  },
  watch: {
    team (value) {
      if (value !== teamsStore.state.currentTeamId) this.owner = null
      this.contacts = null
      this.usage = null

      this.loadProfileData()
    },
  },
  mounted () {
    this.loadProfileData()
  },
  computed: {
    ...mapGetters([
      'profile',
    ]),
    currentModal () {
      return uiStore.getters.currentModal
    },
    currentRightBarPayload () {
      return uiStore.getters.currentRightBarPayload
    },
    leaveTeamButtonLabel () {
      return teamsStore.getters.isSingleGroupTeam(this.currentRightBarPayload)
        ? this.$t('common.leaveSingleGroupTeam')
        : this.$t('common.leaveTeam')
    },
    team () {
      return teamsStore.state.data[this.currentRightBarPayload] ?? {}
    },
    isCurrentTeam () {
      return this.team.uid === teamsStore.state.currentTeamId
    },
    hasRights () {
      const { status } = this.team.me
      return ['admin', 'owner'].indexOf(status) >= 0
    },
    loading () {
      return this.owner === null || this.contacts === null || this.usage === null
    },
  },
  methods: {
    openUsageDynamicModal () {
      uiStore.actions.showModal({ instance: 'MemoryUsage', payload: this.usage })
    },
    openTeamStatisticModal () {
      uiStore.actions.showModal({ instance: 'TeamStatistic', payload: this.usage })
    },
    transformContacts (count) {
      count = count || 0
      return `${count} ${this.$tc('glossary.man', count)}`
    },
    openContacts () {
      if (!this.isCurrentTeam) return
      uiStore.actions.switchRightBarInstance({ instance: 'contacts' })
    },
    openOwnerProfile () {
      if (!this.owner) return
      uiStore.actions.switchRightBarInstance({
        instance: 'contact-profile',
        payload: this.owner.jid,
      })
    },
    inviteContact () {
      if (!this.isCurrentTeam || !this.hasRights) return
      uiStore.actions.showModal({
        instance: 'AddContact',
        payload: { team: this.team.uid, contact: null },
      })
    },
    async leaveTeam () {
      let dialogOptions
      if (this.owner.jid === this.profile.jid) {
        dialogOptions = {
          caption: this.$t('profiles.leaveTeam.caption'),
          content: this.$t('profiles.leaveTeam.contentOwner'),
          formatData: { name: this.team.name },
          acceptCallback: async () => {
            this.$router.push({
              name: 'Chat',
              params: {
                teamId: teamsStore.getters.currentTeam.uid,
                jid: contactsStore.getters.supportChatId,
              },
            })
          },
          acceptLabel: this.$t('profiles.leaveTeam.acceptLabelSupport'),
          acceptError: this.$t('profiles.leaveTeam.acceptError'),
        }

        const context = `{"ops":[{"insert": "${this.$t('profiles.leaveTeam.messageForSupportChat')} \\n"}]}`

        const revision = Date.now()
        await draftsStore.actions.addDraft({
          id: contactsStore.getters.supportChatId,
          context,
          revision: revision,
        })
      } else {
        dialogOptions = {
          caption: this.$t('profiles.leaveTeam.caption'),
          icon: teamsStore.getters.teamIconURL(this.team.uid),
          content: this.$t('profiles.leaveTeam.content'),
          formatData: { name: this.team.name },
          acceptCallback: async () => {
            const { uid } = this.team
            if (this.isCurrentTeam) {
              await api.contacts.delete(this.team.me.jid, uid)
              logout(true)
            } else {
              const { me } = contactsStore.getters.team(uid)
              await api.contacts.delete(me.jid, uid)
              teamsStore.actions.deleteTeam(uid)
            }
          },
          acceptLabel: this.$t('profiles.leaveTeam.acceptLabel'),
          acceptError: this.$t('profiles.leaveTeam.acceptError'),
        }
      }

      uiStore.actions.showModal({
        instance: 'Dialog',
        payload: dialogOptions,
      })
    },

    async loadProfileData () {
      let owner = null
      let contactsCount = null

      const isAdmin = contact => ['owner'].indexOf(contact.status) >= 0
      const contactListFilterPredicate = contact => !contact.botname && !contact.isArchive

      if (isAdmin(this.team.me)) {
        owner = this.team.me
      }

      if (this.isCurrentTeam) {
        contactsCount = contactsStore.getters.teamMembers.filter(contactListFilterPredicate).length

        if (this.hasRights) {
          try {
            this.usage = await api.teams.memoryUsage(this.team.uid)
          } catch (e) {
            defaultLogger.warn(`[TeamProfile err]: ${e}`)
          }
        }
      }

      try {
        let contacts = await api.contacts.getAll(this.team.uid)
        contacts = contacts.filter(contactListFilterPredicate)

        contactsCount = contactsCount || contacts.length

        !owner && contacts.some(contact => {
          return isAdmin(contact) ? (owner = contact) : null
        })
      } catch (e) {
        defaultLogger.warn('!!!', e)
      }

      this.owner = owner || false
      this.contacts = contactsCount
      this.usage = this.usage || {}
    },
  },
}
