//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import {
  teamsStore,
  contactsStore,
  groupsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'
import { Contact, Team, Chat } from '@tada-team/tdproto-ts'

export default {
  name: 'ProfileAvatar',
  components: {
    BaseSpinner,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      source: null,
    }
  },
  computed: {
    isClickable () {
      return this.source && this.source.indexOf('/assets') < 0
    },
    unloadedSource () {
      let icon

      if (this.entity instanceof Team) {
        icon = teamsStore.getters.teamIconURL(this.entity.uid, true)
      } else if (this.entity instanceof Contact) {
        icon = contactsStore.getters.contactIcon(this.entity.jid, true)
      } else if (this.entity instanceof Chat) {
        icon = groupsStore.getters.icon(this.entity.jid, true)
      } else {
        icon = this.entity.largeIcon
      }

      return this.checkForProfilePlaceholder(icon)
    },
  },
  watch: {
    unloadedSource: {
      handler (unloadedSource) {
        if (!unloadedSource) return

        const image = new window.Image()
        image.onload = () => { this.source = image.src }

        image.src = unloadedSource
      },
      immediate: true,
    },
  },
  methods: {
    openAvatar () {
      if (!this.isClickable) return

      const { getters } = this.$store

      const { jid, uid } = this.entity
      const entityId = jid || uid
      if (!entityId) {
        this.showAvatar()
        return
      }

      let label = ''
      let name = ''

      const entity = getters.entity(entityId)
      if (entity) {
        label = entity.chatType === 'group' ? this.$t('modals.AvatarSelector.ofChat') : this.$t('modals.AvatarSelector.ofContact')
        name = entity.displayName
      } else {
        const team = teamsStore.state.data[entityId]
        if (team) {
          name = team.name
          label = this.$t('modals.AvatarSelector.ofTeam')
        }
      }

      this.showAvatar(`${this.$t('modals.AvatarSelector.avatar')} ${label} ${name}`.trim())
    },
    showAvatar (name = this.$t('modals.AvatarSelector.avatar')) {
      uiStore.actions.showModal({
        instance: 'FileViewer',
        payload: { content: this.source, type: 'image', name },
      })
    },
    checkForProfilePlaceholder (url) {
      const theme = uiSettingsStore.getters.theme
      if (!theme || theme.toLowerCase() !== 'orange') return url

      if (url.indexOf('/placeholders/') < 0) return url

      const name = url.substr(url.lastIndexOf('/') + 1)
      const entityType = name.substring(name.lastIndexOf('-') + 1, name.lastIndexOf('.'))

      const highResolution = window.devicePixelRatio > 1
      const suffix = highResolution ? '-big' : ''

      return `/assets/i/placeholders/profiles/orange-${entityType}${suffix}.jpeg`
    },
  },
}
