//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { showLeaveGroupDialog } from '@/components/Modals/Default/Dialog/Presets'

import ProfileAvatar from '../Avatar'
import ScrollableArea from '@/components/UI/ScrollableArea'
import CommonMixin from '../CommonMixin'

import Description from '../Description'
import MembersPreview from './MembersPreview'
import {
  groupsStore,
  uiStore,
} from '@/store'

export default {
  name: 'GroupProfile',
  mixins: [CommonMixin],
  components: {
    'gear-icon': createSVGComponent({ icon: 'sett', size: 20 }),
    'close-icon': createSVGComponent({ icon: 'close', size: 20 }),
    'bullhorn-icon': createSVGComponent({ icon: 'bullhorn_new', size: 16 }),

    'scrollable-area': ScrollableArea,
    ProfileAvatar,
    Description,
    MembersPreview,
  },
  computed: {
    hasRights () {
      // group may be undefined, when the store is reset
      return this.group?.canChangeSettings ||
        this.group?.canChangeMemberStatus ||
        this.group?.canAddMember ||
        this.group?.canRemoveMember ||
        this.group?.canDelete
    },
    group () {
      // if not found, then we reset to the first in the list
      return (
        groupsStore.state.data[uiStore.getters.currentRightBarPayload] ||
        groupsStore.state.data[Object.keys(groupsStore.state.data)[0]]
      )
    },
    settingsModal () {
      const payload = { type: 'edit', jid: this.group.jid }
      return { instance: 'GroupForm', payload }
    },
    readOnly () {
      return this.group?.readOnly || false
    },
    displayName () {
      if (!this.group) return ''
      return this.group.displayName
    },
  },
  methods: {
    leave () {
      showLeaveGroupDialog(this.group.jid)
    },
  },
}
