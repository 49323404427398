//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      focused: false,
      warning: '',
    }
  },
  created () {
    this.previousValue = ''
  },
  methods: {
    unfocus (e) {
      const { target } = e
      target && target.blur()
    },
    toggleFocus (e) {
      this.$emit('toggleFocus', this.focused = e.type === 'focus')
    },
    emitEnter (e) {
      const value = e.target.value
      if (value.length < this.minLength) {
        this.warning = this.$t('header.hintWarning', {
          verb: this.$tc('glossary.should', this.minLength),
          min: this.$tc('glossary.character', this.minLength),
        })
        return
      }

      this.warning = ''
      this.$emit('enter')
      this.$emit('input', value)
    },
    emitInput (e) {
      this.warning = ''
      const value = e.target.value
      this.$emit('input', value)
    },
  },
}
