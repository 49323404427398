//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

export default {
  name: 'SearchTrigger',
  components: {
    'search-icon': createSVGComponent({ icon: 'search', size: 24 }),
  },
  props: {
    total: Number,
  },
  data () {
    return {
      focused: false,
      text: '',
    }
  },
  computed: {
    isInFocus () {
      return this.text || this.focused
    },
  },
  watch: {
    text (value) {
      this.$emit('onFilterChange', value)
    },
  },
  methods: {
    handleEscapePress (event) {
      this.text = ''
      this.focused = false

      event.target.blur()
    },
    handleFocusChange (event) {
      const focus = event.type === 'focus'
      this.focused = focus
    },
    focus () {
      const { input } = this.$refs
      input && input.focus()
    },
  },
}
