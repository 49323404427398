//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  createSVGComponent,
  createRawSVGComponent,
} from '@/components/UI/icons/Factory/index.jsx'
import PopupMenuWrapper from '@/components/UI/Wrappers/PopupMenuWrapper'
import { uiStore } from '@/store'

export default {
  name: 'ContactsMenu',
  components: {
    'popup-menu': PopupMenuWrapper,
    'plus-icon': createRawSVGComponent({
      icon: 'plus_icon',
      permanentlyActive: true,
    }),
  },
  data: () => ({
    options: [],
  }),
  created () {
    this.options = []

    this.options.push({
      key: 'add-section',
      name: this.$t('contacts.addDepartment'),
      icon: createSVGComponent({
        icon: 'add_section',
        size: 24,
        permanentlyActive: true,
      }),
      action: () => {
        uiStore.actions.showModal({ instance: 'SectionModal' })
      },
      dataCy: 'contacts-menu-add-section',
    })

    this.options.push({
      key: 'add-contact',
      name: this.$t('contacts.addContact'),
      icon: createSVGComponent({
        icon: 'add_contact',
        size: 24,
        permanentlyActive: true,
      }),
      action: () => {
        uiStore.actions.showModal({ instance: 'AddContact' })
      },
    })

    if (window.FEATURES.single_group_teams) {
      this.options.push({
        key: 'create-single-group-team',
        name: this.$t('contacts.createSingleGroupTeam'),
        icon: createSVGComponent({
          icon: 'users',
          size: 24,
          permanentlyActive: true,
        }),
        action: () => {
          uiStore.actions.showModal({
            instance: 'TeamCreation',
            payload: { mono: true },
          })
        },
      })
    }
  },
}
