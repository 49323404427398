//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent, createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { transformName } from './Lists/Utils.ts'

export default {
  name: 'BarItem',
  components: {
    'close-icon': createRawSVGComponent({ icon: 'close', size: 14, sizeStorage: 16 }),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    showAvatars: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatar () {
      return this.showAvatars && this.data.avatar
    },
    icon () {
      const { icon } = this.data
      if (!icon) return null

      if (typeof icon !== 'string') return icon

      switch (icon) {
        case 'public-group-icon': return createSVGComponent({ icon: 'group_opened', width: 9.5, height: 10, sizeStorage: 16 })
        case 'default-group-icon': return createSVGComponent({ icon: 'group_closed', width: 8.75, height: 10, sizeStorage: 16 })
        default: return null
      }
    },
  },
  methods: {
    transformName,
  },
}
