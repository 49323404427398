import Vue from 'vue'

export const Events = {
  SET_TASK_SEARCH: 'SET_TASK_SEARCH',
  SET_SECTION_FILTER: 'SET_SECTION_FILTER',
  SET_ASSIGNEE_FILTER: 'SET_ASSIGNEE_FILTER',
  TOP_SPLIT_COLLAPSE_STATE: 'TOP_SPLIT_COLLAPSE_STATE',
  ADD_TASK_ITEM: 'ADD_TASK_ITEM',
  TASK_DESK_LOADING: 'TASK_DESK_LOADING',
}

export const TaskEventBus = new Vue()
