//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterBar from './FilterBar'

import './Lists/style.scss'
import FileTypeList from './Lists/FileTypeList'
import ChatList from './Lists/ChatList'

import ScrollableArea from '@/components/UI/ScrollableArea'
import { format } from 'quasar'

import { teamsStore } from '@/store'

const { capitalize } = format

export default {
  name: 'FilterPanel',
  components: {
    'scrollable-area': ScrollableArea,

    FilterBar,
    FileTypeList,
    ChatList,
  },
  data () {
    return {
      fileTypes: [],
      chats: [],
      senders: [],
      chatFilter: '',
      senderFilter: '',
    }
  },
  computed: {
    showChatsFilter () {
      return !teamsStore.getters.isSingleGroupTeam()
    },
    sendertext () {
      return capitalize(this.$t('glossary.sender')) + ':'
    },
  },
  watch: {
    fileTypes (value) {
      const list = (value || []).map(item => item.value)
      this.$emit('onTypeListChanged', list)

      this.$nextTick(this.checkHeight)
    },
    chats (value) {
      const list = (value || []).map(item => item.value)
      this.$emit('onChatListChanged', list)

      this.$nextTick(this.checkHeightAndScroll)
    },
    senders (value) {
      const list = (value || []).map(item => item.value)
      this.$emit('onSenderListChanged', list)

      this.$nextTick(this.checkHeightAndScroll)
    },
  },
  created () {
    this.maxHeight = 200
    this.fileTypesPlaceholder = this.$t('modals.FileBrowser.allFileTypes')
  },
  methods: {
    setupFilter (filter, value) {
      this.$set(this, filter, Array.isArray(value) ? value : [value])
    },
    handleFilterChanged (value) {
      this.chatFilter = value
    },
    checkHeight () {
      const { $el: element } = this
      if (!element) return

      const { scrollableArea } = this.$refs
      if (!scrollableArea) return

      const height = scrollableArea.getContentHeight(true)
      element.style.height = height >= this.maxHeight ? `${this.maxHeight}px` : 'auto'

      return scrollableArea
    },
    checkHeightAndScroll (value) {
      const scrollableArea = this.checkHeight()

      value = value === undefined ? true : value
      value && scrollableArea && scrollableArea.scrollDown()
    },
    checkHeightAndScrollTop (value) {
      const scrollableArea = this.checkHeight()

      value = value === undefined ? true : value
      value && scrollableArea && scrollableArea.scrollTop()
    },
  },
}
