//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultLogger } from '@/loggers'
import { mapGetters, mapActions } from 'vuex'
import { tasksStore, teamsStore } from '@/store'
import * as actionTypes from '@/store/actionTypes'
import api from '@/api/v3/'

export default {
  name: 'TaskListRefactor',
  components: {
    'task-desk-card-compact': () => import('./TaskDeskCardCompact'),
    'task-desk-card-full': () => import('./TaskDeskCardFull'),
    'task-bar-card-compact': () => import('./TaskBarCardCompact'),
    'task-bar-card-full': () => import('./TaskBarCardFull'),
  },
  props: {
    taskObjects: Array,
    compactList: Boolean,
    tabKey: String,
    filters: Object,
    sort: {
      required: false,
      // type: String,
      // required: true
      // validator (value) {
      //   const options = [
      //     'activity',
      //     'new',
      //     'old',
      //     'deadline'
      //   ]
      //   return options.includes(value)
      // }
    },
    jids: Array,
    notHoverable: Boolean,
    taskDragMetadata: Object,
    // tab: {
    //   type: String,
    //   required: true
    // },
    type: {
      type: String,
      required: true,
      validator (value) {
        const options = [
          'rightBar',
          'taskDesk',
        ]
        return options.includes(value)
        // return ['rightBar', 'taskDesk'].indexOf(value) !== -1
      },
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'task-bar',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      // TODO: ugly! will probably stay like this till adaptive layout is rolling out
      defaultCardsSizes: {
        rightBar: {
          full: { minHeight: '99px' },
          compact: { minHeight: '62px' },
        },
        taskDesk: {
          full: { minHeight: '69px' },
          compact: { minHeight: '63px' },
        },
      },
    }
  },
  methods: {
    ...mapActions([
      actionTypes.UPDATE_CHAT,
    ]),
    getTask (jid) {
      return tasksStore.state.data[jid]
    },
    scrollTop () {
      this.$refs.scrollContainer.scrollTop = 0
    },
    handleVisibility (task, visible) {
      visible && this.$emit('seen', task.jid)
    },
    async loadTasksByTab () {
      const params = {}

      const { sort, assignee, owner, tag, section } = this.filters

      if (sort) params.sort = sort
      if (assignee?.length > 0) params.assignee = assignee
      if (owner?.length > 0) params.owner = owner
      if (tag?.length > 0) params.tag = tag
      if (section !== undefined) {
        params.section = section ?? ''
      }

      let result

      params.unread_first = this.unreadFirst

      try {
        result = await api.tasks.getByTab(this.filters.tab, params)
      } catch (e) {
        defaultLogger.error('Error in loadTasksByTab', e)
        return
      }

      tasksStore.mutations.addTasks(result.objects)
      result.chats.forEach(this.UPDATE_CHAT)
    },
    navTo (ev, link, task) {
      if (ev.ctrlKey || ev.shiftKey || ev.metaKey) {
        return
      }
      ev.preventDefault()
      if (!this.standalone && !this.isThisTaskOnScreen(task)) {
        this.$router.push({
          name: 'Chat',
          params: {
            teamId: teamsStore.getters.currentTeam.uid,
            jid: task.jid,
          },
        })
      }
    },
    isThisTaskOnScreen (task) {
      if (!task) return
      if (this.notHoverable) return

      return this.currentChat === task.jid
    },

    // SORTING UTILS
    compareDates (date1, date2, ascending = true) {
      let result = 0

      if (date1 && !date2) return -1
      else if (!date1 && date2) return 1
      else if (date1 && date2) result = Date.parse(date2) - Date.parse(date1)
      else return result

      return result * (ascending ? -1 : 1)
    },
  },
  computed: {
    ...mapGetters([
      'currentChat',
      'profile',
    ]),
    ...mapGetters({
      unreadFirst: 'unreadFirst',
    }),
    newTasks () {
      return [...this.taskObjects]
    },
    team () {
      return window.currentTeamId
    },
    intersectionSize () {
      const type = this.type
      const display = this.compactList ? 'compact' : 'full'
      return this.defaultCardsSizes[type][display]
    },
    cardType () {
      let type
      if (this.type === 'taskDesk') {
        if (this.compactList) {
          type = 'task-desk-card-compact'
        } else {
          type = 'task-desk-card-full'
        }
      } else {
        if (this.compactList) {
          type = 'task-bar-card-compact'
        } else {
          type = 'task-bar-card-full'
        }
      }
      return type
    },
    taskDeskCardProps () {
      const props = {
        class: 'q-mx-xs q-mb-md q-mt-xs',
      }
      return props
    },
    taskBarCardProps () {
      const props = {
        style: 'margin-bottom: 12px;',
      }
      return props
    },
    taskCardProps () {
      const commonProps = {
        notHoverable: this.notHoverable,
        showDir: true,
        compact: this.compactList,
        dragMetadata: this.taskDragMetadata,
        type: this.type,
      }
      const props = {
        ...commonProps,
        ...this.type === 'taskDesk' ? this.taskDeskCardProps : this.taskBarCardProps,
      }
      return props
    },
    tasks () {
      // if no tasks jids passed from parent - get all tasks from store
      const result = this.jids
        ? this.jids.map(jid => this.getTask(jid))
        : { ...tasksStore.state.data }
      return result
    },
    filteredTasks () {
      defaultLogger.log('filtering tasks')
      // this.loadTasksByTab()
      const result = Object.values(tasksStore.state.data).filter(task => {
        if (!task.tabs?.includes(this.filters.tab)) return false

        if (this.filters.section !== undefined &&
          this.task.section !== this.filters.section
        ) return false

        if (this.filters.owner.length > 0 &&
          !this.filters.owner.some(
            owner => owner === task.owner,
          )) return false

        if (this.filters.assignee.length > 0 &&
          !this.filters.assignee.some(
            assignee => assignee === task.assignee,
          )) return false

        if (this.filters.tag.length > 0 &&
          !this.filters.tag.some(
            tag => task.tags?.includes(tag),
          )) return false

        return true
      })

      return result
    },
    sortedTasks () {
      defaultLogger.log('sorting tasks')
      return this.filteredTasks.sort((t1, t2) => {
        let result = 0

        if (this.sort === 'deadline') {
          result = this.compareDates(
            t1?.deadline,
            t2?.deadline,
            true,
          )
        } else if (this.sort === 'new') {
          result = this.compareDates(
            t1?.created,
            t2?.created,
            false,
          )
        } else if (this.sort === 'old') {
          result = this.compareDates(
            t1?.created,
            t2?.created,
            true,
          )
        } else if (this.sort === 'activity') {
          result = this.compareDates(
            t1?.lastMessage?.created,
            t2?.lastMessage?.created,
            false,
          )
        }

        // try sorting by activity if previous sort resolved to 0
        if (result !== 0) return result
        else if (this.sort !== 'activity') {
          result = this.compareDates(
            t1?.lastMessage?.created,
            t2?.lastMessage?.created,
            false,
          )
        }

        // if previous still resolves to zero -> just sort by creation date
        if (result !== 0) return result
        else if (this.sort !== 'new') {
          result = this.compareDates(
            t1?.created,
            t2?.created,
            false,
          )
        }

        return result
      })
    },
    showSearchEverywhere () {
      return false
      // return (this.filters.tags.length || this.filters.searchQuery) && !(this.filters.new && this.filters.done && this.filters.in && this.filters.out && this.filters.copy)
    },
    showNothingFound () {
      return !this.isLoading && this.taskObjects.length === 0
    },
  },
}
