//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    currentValue () {
      return this.value || this.options[0].key
    },
    title () {
      return this.options.find(option => option.key === this.currentValue).title
    },
  },
  methods: {
    handleClick (key) {
      if (key === this.currentValue) return
      this.$emit('input', key)
    },
  },
}
