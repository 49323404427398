






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EmojiDropdown extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly emoji!: string
}
