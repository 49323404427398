import { uiSettingsStore } from '@/store'
import './styles.scss'

export default {
  name: 'SliderChecker',
  functional: true,
  props: ['value', 'width', 'height', 'thumbSize', 'textState', 'edgeMargin', 'disabled'],
  render (h, context) {
    const { props, listeners } = context
    let { value, width, height, thumbSize, textState, edgeMargin, disabled } = props

    const className = 'slider-checker ' + uiSettingsStore.getters.language

    width = width || 44
    height = height || 20

    thumbSize = thumbSize || height - Math.floor(height / 3)

    const padding = edgeMargin || 4

    const style = { width: `${width}px`, height: `${height}px` }
    console.log(disabled)
    return (
      <label
        class={className}
        style={style}
      >
        <input type="checkbox" class="o-absolute-stretch" checked={value} onClick={listeners.click || (() => {})} disabled={disabled} />
        <div
          class="slider-checker__wrapper"
          style={{ marginLeft: `${padding}px`, width: `${width - thumbSize - padding * 2}px` }}
        >
          <div
            class="slider-checker__thumb"
            style={{ width: `${thumbSize}px`, height: `${thumbSize}px` }}
          />
        </div>
        <div class="slider-checker__mask o-absolute-stretch o-bg-color" />
        {textState && (<div class="slider-checker__text-state o-flex-row" />)}
      </label>
    )
  },
}
