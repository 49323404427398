//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sortEntitiesByName, transformEntityName } from '@/utils'
import PopupMenuWrapper from '@/components/UI/Wrappers/PopupMenuWrapper'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { format } from 'quasar'
import {
  teamsStore,
  contactsStore,
  groupsStore,
  uiStore,
} from '@/store'

const { capitalize } = format

export default {
  name: 'GroupMembersPreview',
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      max: 7,
    }
  },
  components: {
    'more-icon': createSVGComponent({ icon: 'more', size: 20 }),
    'contact-icon': createSVGComponent({ icon: 'add_contact', size: 20, sizeStorage: 24, permanentlyActive: true }),
    'popup-menu': PopupMenuWrapper,
  },
  computed: {
    members () {
      const members = groupsStore.getters.members(uiStore.getters.currentRightBarPayload)
      if (!teamsStore.getters.currentTeam.hideArchivedUsers) return sortEntitiesByName(members)
      return sortEntitiesByName(members.filter(member => !member.isArchive))
    },
    hasRights () {
      return this.group.canAddMember
    },
    adminText () {
      return capitalize(this.$t('glossary.admin'))
    },
    caption () {
      return capitalize(this.$t('glossary.member_plural')) + (this.members.length > 0 ? ` (${this.members.length})` : '')
    },
  },
  created () {
    groupsStore.actions.loadMembers(this.group.jid)
  },
  methods: {
    icon (jid) {
      return contactsStore.getters.contactIcon(jid)
    },
    options (contact) {
      if (!contact) return

      let options = [
        { name: this.$t('common.openProfile'), action: () => this.openProfile(contact) },
      ]

      const isMe = this.isMe(contact)
      const isAdmin = this.isAdmin(contact)

      if (isMe) return options

      const { canChangeMemberStatus, canRemoveMember } = this.group
      if (canChangeMemberStatus || canRemoveMember) {
        options = options.concat([{ divider: true }])
      }

      if (canChangeMemberStatus) {
        options = options.concat([
          { name: !isAdmin ? this.$t('profiles.makeAdmin') : this.$t('profiles.makeMember'), action: () => this.changeContactStatus(contact) },
        ])
      }

      if (canRemoveMember) {
        options = options.concat([
          { name: this.$t('common.removeMember'), action: () => this.excludeContact(contact) },
        ])
      }

      return options
    },
    openProfile (contact) {
      uiStore.actions.switchRightBarInstance({
        instance: 'contact-profile',
        payload: contact.jid,
      })
    },
    openChat (contact) {
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: contact.jid,
        },
      })
    },
    async excludeContact (contact) {
      const action = async () => {
        await groupsStore.actions.deleteMember({ groupId: this.group.jid, memberId: contact.jid })
      }
      const icon = contactsStore.getters.contactIcon(contact.jid)
      const dialogOptions = {
        caption: this.$t('profiles.excludeContact.caption'),
        icon,
        content: this.$t('profiles.excludeContact.content'),
        formatData: { c: contact.displayName, g: this.group.displayName },
        acceptCallback: action,
        acceptLabel: this.$t('profiles.excludeContact.acceptLabel'),
        acceptError: this.$t('profiles.excludeContact.acceptError'),
      }

      uiStore.actions.showModal({
        instance: 'Dialog',
        payload: dialogOptions,
      })
    },
    async changeContactStatus (contact) {
      const groupId = this.group.jid
      const status = this.isAdmin(contact) ? 'member' : 'admin'

      try {
        await groupsStore.actions.changeMemberStatus({ groupId, memberId: contact.jid, status })
      } catch (e) {
        console.warn(`[MembersPreview err]: ${e}`)
      }
    },
    transformEntityName,
    isAdmin (contact) {
      for (const { jid, status } of this.group.members) {
        if (contact.jid === jid) return status === 'admin'
      }
    },
    isMe (contact) {
      const { getters } = this.$store
      return contact.jid === getters.getUserId
    },
    addMembers () {
      uiStore.actions.showModal({
        instance: 'all-members',
        payload: {
          jid: this.group.jid,
          edit: true,
        },
      })
    },
    showMembers () {
      uiStore.actions.showModal({
        instance: 'all-members',
        payload: {
          jid: this.group.jid,
        },
      })
    },
  },
}
