//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { contactsStore, groupsStore } from '@/store'
import { isMatch } from '@/utils'
import { entityTransform, transformName, isItemInList } from './Utils.ts'
import ListMixin from './ListMixin'
import ScrollableArea from '@/components/UI/ScrollableArea'
import { createSVGComponent } from '@/components/UI/icons/Factory'
import i18n from '@/i18n'

export default {
  name: 'ChatList',
  mixins: [ListMixin],
  components: {
    'scrollable-area': ScrollableArea,
    'public-group-icon': createSVGComponent({ icon: 'group_opened', width: 9.5, height: 10, sizeStorage: 16 }),
    'default-group-icon': createSVGComponent({ icon: 'group_closed', width: 8.75, height: 10, sizeStorage: 16 }),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    filterPredicate: {
      type: Function,
      default: null,
    },
    maxDisplay: {
      type: Number,
      default: 10,
    },
    filter: {
      type: String,
      default: '',
    },
    directOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items () {
      const directItems = contactsStore.getters.teamMembers.map(this.entityTransform)

      if (this.directOnly) {
        return directItems
      }

      const groupItems = groupsStore.getters.list.map(item => {
        const tempItem = this.entityTransform(item)
        tempItem.icon = this.$options.components[tempItem.icon] || tempItem.icon
        return tempItem
      })

      return directItems.concat(groupItems)
    },
    list () {
      return this.items.filter(item => {
        return !isItemInList(this.value, item) && isMatch(item.name, this.filter)
      })
    },
    directList () {
      return this.list.filter(item => !item.isGroup).sort(this.alphabeticSort)
    },
    groupList () {
      return this.list.filter(item => item.isGroup).sort(this.alphabeticSort)
    },
    containers () {
      const contacts = [{
        name: this.$t('modals.FileBrowser.contacts'),
        list: this.directList,
      }]

      if (this.directOnly) {
        return contacts
      }

      return [{
        name: this.$t('modals.FileBrowser.groups'),
        list: this.groupList,
      }, ...contacts]
    },
    listHeight () {
      let currentItemCount = this.directOnly ? 1 : 2

      this.containers.forEach(container => {
        const { list } = container
        currentItemCount += list.length || 1
      })

      return Math.min(this.maxListHeight, currentItemCount * 26) + 7
    },
  },
  created () {
    this.maxListHeight = this.maxDisplay * 26
  },
  methods: {
    entityTransform,
    transformName,
    alphabeticSort (a, b) {
      if (a.normal !== b.normal) return a.normal ? -1 : 1
      return a.name.localeCompare(b.name, i18n.locale, { ignorePunctuation: true })
    },
  },
}
