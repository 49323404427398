


























import IconTrash from '@/components/UI/icons/IconTrash.vue'
import IconPen from '@/components/UI/icons/IconPen.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TdTooltip } from 'td-ui'

type ControlBtn = {
  tooltip: string,
  icon: Vue.Component,
  action: () => unknown,
}

@Component({
  components: {
    TdTooltip,
  },
})
export default class ContactCardControls extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly jid!: string

  @Prop({
    type: Boolean,
    default: false,
  }) readonly canDelete!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) readonly canEdit!: boolean

  private get btns (): ControlBtn[] {
    const b: ControlBtn[] = []

    if (this.canDelete) {
      b.push({
        tooltip: this.deleteTooltipText,
        action: () => this.$emit('remove'),
        icon: IconTrash,
      })
    }

    if (this.canEdit) {
      b.push({
        tooltip: this.$t('common.edit').toString(),
        action: () => this.$emit('edit'),
        icon: IconPen,
      })
    }

    return b
  }

  private get isGroup (): boolean {
    return this.$store.getters.chatType(this.jid) === 'group'
  }

  private get deleteTooltipText (): string {
    const t = this.isGroup
      ? this.$t('profiles.leaveGroup')
      : this.$t('profiles.removeFromTeam')
    return t.toString()
  }
}
