export default {
  methods: {
    toggleSelection (item) {
      let temp = [...this.value]
      if (!item) {
        temp = []
      } else {
        const index = this.value.indexOf(item)
        index >= 0 ? temp.splice(index, 1) : temp.push(item)
      }
      this.$emit('input', temp)
    },
  },
}
