//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import ScrollableArea from '@/components/UI/ScrollableArea'
import Messages from '@/store/messages'
import { uiStore } from '@/store'
import { loadMissingChatsFromMessages } from '@/utils'
import { mapGetters } from 'vuex'
import FileItem from './FileItem'
import FilterPanel from './FilterPanel'
import { entityTransform } from './FilterPanel/Lists/Utils.ts'
import SearchTrigger from './SearchTrigger'
import { debounce, throttle } from 'quasar'

export default {
  name: 'FileBrowser',
  components: {
    'scrollable-area': ScrollableArea,
    BaseSpinner,

    SearchTrigger,
    FilterPanel,
    FileItem,
  },
  data () {
    return {
      isStarting: true,
      isLoadingMore: false,
      isEndReached: false,
      messages: [],
      total: 0,

      chats: [],
      fileTypes: [],
      senders: [],
      nameFilter: '',
      filterRequestTimestamp: null,
    }
  },
  computed: {
    ...mapGetters([
      'chatExists',
      'currentChat',
      'entity',
    ]),
    currentRightBarPrimaryPayload () {
      return uiStore.getters.currentRightBarPrimaryPayload
    },
  },
  mounted () {
    const { filterPanel } = this.$refs

    let isStartupFilterPresent = false
    if (filterPanel) {
      const payload = this.currentRightBarPrimaryPayload || {}
      const chatId = payload.chatId || this.currentChat
      const entity = this.entity(chatId)

      isStartupFilterPresent = !!entity
      isStartupFilterPresent && filterPanel.setupFilter('chats', entityTransform(entity))
    }

    if (!isStartupFilterPresent) {
      this.filterRequestTimestamp = Date.now()
      this.fetchNext(this.filterRequestTimestamp)
    }

    const { scrollableArea } = this.$refs
    const { content } = scrollableArea.$refs

    this.throttledOnScroll = throttle(this.onScroll, 75)

    content.addEventListener('scroll', this.throttledOnScroll)
  },
  created () {
    this.debouncedApply = debounce(this.applyChatFilter, 250)
  },
  beforeDestroy () {
    const { scrollableArea } = this.$refs
    if (!scrollableArea) return

    const { content } = scrollableArea.$refs
    content && content.removeEventListener('scroll', this.throttledOnScroll)

    Messages.clearStandaloneMessages()
  },
  methods: {
    fileFilterChanged (types) {
      this.fileTypes = types

      this.applyChatFilter()
    },
    chatFilterChanged (chats) {
      this.chats = chats

      this.applyChatFilter()
    },
    senderFilterChanged (senders) {
      this.senders = senders

      this.applyChatFilter()
    },
    applyChatFilter () {
      this.reset()

      this.filterRequestTimestamp = Date.now()
      this.fetchNext(this.filterRequestTimestamp, this.chats.join(), this.fileTypes.join(), this.nameFilter, this.senders.join())
    },
    filterFilesByName (filter) {
      const pre = this.nameFilter
      this.nameFilter = filter.length < 3 ? '' : filter
      this.nameFilter !== pre && this.debouncedApply()
    },
    onScroll (e) {
      const { target } = e

      const isThresholdPassed = target.scrollHeight - target.scrollTop - target.clientHeight < 100
      if (!this.nameFilter && !this.isEndReached && !this.isLoadingMore && isThresholdPassed) {
        this.isLoadingMore = true
        this.fetchNext()
      }
    },
    createPagination (chat, type, text, sender) {
      const params = { has_upload: true }
      return api.messages.filter(Object.assign({}, params, { chat, type, text, sender }))
    },
    async fetchNext (timestamp, chat, type, text, sender) {
      if (!this.pagination) this.pagination = this.createPagination(chat, type, text, sender)

      const messages = await this.pagination.next()

      if (this.isStarting) {
        if (timestamp !== this.filterRequestTimestamp) return
        this.total = this.pagination.count
        this.isStarting = false
      }

      this.isLoadingMore = false
      if (!messages) {
        this.isEndReached = true
        return
      }

      messages.forEach(newM => {
        // clear out duplicates (should there be any)
        if (this.messages.some(m => m.messageId === newM.messageId)) return
        this.messages.push(newM)
      })

      loadMissingChatsFromMessages(messages)
    },
    reset () {
      this.isStarting = true
      this.isLoadingMore = false
      this.isEndReached = false
      this.messages = []

      this.pagination = null
    },
  },
}
