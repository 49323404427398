import { getAlignStyle, fixAlignStyle, getCenteredAlign } from '@/components/UI/Wrappers/utils'

export default {
  props: {
    alignBy: {
      type: String,
      default: 'window',
    },
    alignOffset: Object,
    autoFix: Boolean,
    dropside: String,
    horizontalDropsize: String,
  },
  data () {
    return {
      open: false,
      alignStyle: {},
    }
  },
  methods: {
    fixMenuPosition () {
      const { menu } = this.$refs
      if (!menu) return

      const fixedAlign = fixAlignStyle(menu, this.alignOffset, this.alignStyle)
      if (!fixedAlign) return

      Object.assign(this.alignStyle, fixedAlign)
    },
    centeringMenuPotision () {
      const { menu, trigger } = this.$refs
      if (!menu || !trigger) return

      const slot = trigger.firstChild
      if (!slot) return

      const align = getCenteredAlign(slot, menu)
      Object.assign(this.alignStyle, align)

      this.ready = true
    },
    toggleMenu (e) {
      const { currentTarget: target, type } = e
      switch (type) {
        case 'blur':
          this.open = false

          if (this.centering) {
            this.ready = false
          }
          break
        case 'mousedown': {
          if (!this.open) return

          e.preventDefault()
          target.blur()
          break
        }
        default: {
          this.alignStyle = getAlignStyle(target, this.alignBy, this.alignOffset, this.dropside, this.horizontalDropsize)
          this.open = true
        }
      }
    },
  },
}
