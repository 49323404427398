import { uiStore } from '@/store'
import { parseEmoji, preventHtmlTags } from '@/utils'

export default {
  computed: {
    isGearActive () {
      const { getters } = this.$store
      return getters.currentModal === this.settingsModal.instance
    },
  },
  methods: {
    transformString (name) {
      name = name || ''
      name = preventHtmlTags(name)
      return parseEmoji(name)
    },
    openSettings () {
      if (!this.settingsModal) return

      uiStore.actions.showModal(this.settingsModal)
    },
    close () {
      uiStore.actions.switchRightBarInstance()
    },
  },
}
