


















import { Vue, Component } from 'vue-property-decorator'
import { Contact } from '@tada-team/tdproto-ts'
import { Getter } from 'vuex-class'
import { meetingsStore } from '@/store'

@Component({
  components: {
    Header: () => import('./Header/index.vue'),
    Filters: () => import('./Filters/index.vue'),
    List: () => import('./List/index.vue'),
    CreateBtn: () => import('@/components/Meetings/components/CreateBtn.vue'),
  },
})
export default class MeetingsRightBar extends Vue {
  // TODO: remove after add filters
  private endDate = new Date(2100, 0, 1)

  @Getter private readonly profile!: Contact

  get startDate () {
    return meetingsStore.state.isAllMeetings
      ? new Date(2000, 0, 1)
      : new Date()
  }

  private get canCreateMeeting () {
    return this.profile.canCreateMeeting
  }
}
