

















































// import Blurhash from '@/components/UI/Blurhash.vue'
import { defaultLogger } from '@/loggers'
import { IconData, PublicStatus } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TdTooltip } from 'td-ui'
import i18n from '@/i18n'

type IconProps = {
  type: 'url',
  url: string,
} | {
  type: 'letters',
  letters: string,
  color: string,
// } | {
//   type: 'blurhash',
//   blurhash: string,
//   height: number,
//   width: number,
//   url: string,
}

@Component({
  components: {
    // Blurhash,
    IconSmartphoneFill:
      () => import('@tada/icons/dist/IconSmartphoneFill.vue'),
    TdTooltip,
  },
  inheritAttrs: false,
})
export default class BaseAvatar extends Vue {
  @Prop({ type: Object, required: true }) private readonly icons!: IconData
  @Prop({ type: Boolean }) private readonly isAfk!: boolean
  @Prop({ type: Boolean }) private readonly isMobile!: boolean
  @Prop({ type: Boolean }) private readonly isOnline!: boolean
  @Prop({ type: Object }) private readonly publicStatus?: PublicStatus

  get avatar (): IconProps {
    const { color, letters } = this.icons
    if (letters && color) return { type: 'letters', letters, color }

    // const { blurhash, lg, sm } = this.icons
    // if (blurhash) {
    //   const height = sm.height ?? lg.height
    //   const width = sm.width ?? lg.width
    //   const url = sm.url ?? lg.url
    //   return { type: 'blurhash', blurhash, height, width, url }
    // }

    const { lg, sm } = this.icons
    if (sm) return { type: 'url', url: sm.url }
    if (lg) return { type: 'url', url: lg.url }

    if (!this.icons.stub) {
      defaultLogger.warn('Unable to create avatar from ', this.icons)
      // TODO: add Sentry
    }

    return { type: 'url', url: this.icons.stub ?? '' }
  }

  get avatarStyle (): Partial<CSSStyleDeclaration> {
    if (this.avatar.type !== 'letters') return {}
    return { backgroundColor: this.avatar.color }
  }

  get lang () {
    return i18n.locale
  }
}
