









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseSpinner extends Vue {
  @Prop({ type: String, default: '2em' }) private readonly size?: string
  @Prop({ type: String, default: 'base-brand' }) private readonly color?: string
}
