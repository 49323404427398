//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { matExpandMore } from '@quasar/extras/material-icons'
import { format } from 'quasar'
import { tasksStore, uiSettingsStore } from '@/store'

const { capitalize } = format

export default {
  components: {
    IconFire: () => import('@/components/UI/icons/IconFire'),
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
    }
  },
  created () {
    this.isDev = window.FEATURES.is_testing
    this.matExpandMore = matExpandMore
  },
  methods: {
    handleClick (key) {
      if (this.value === key) return
      const actions = {
        in: 'Входящие',
        my: 'Мои',
        out: 'Исходящие',
        active: 'Все',
        expired: 'Просроченные',
        done: 'Готово',
      }
      actions[key] && this.sendGoal(`Частота кликов по вкладке «${actions[key]}»`)
      this.$emit('input', key)
    },
    /**
     * Send task goal
     * @param message
     */
    sendGoal (message) {
      window.goal('taskControls', { taskControls: message })
    },
  },
  computed: {
    unreadTasksCounter () {
      return tasksStore.getters.unreadCounter
    },
    unreadTasksNotice () {
      return tasksStore.getters.unreadNoticedCounter
    },
    useExperimentalDesign () {
      return uiSettingsStore.getters.uiSettings.experimentalTasksTabsDesign
    },
    currentTab () {
      return this.tabs.find(t => t.key === this.value)
    },
    tabs () {
      return Object.values(tasksStore.state.tabs).map(tab => {
        const { key } = tab

        let counter = tasksStore.getters.unreadCountersByTab[key]
        const showCounter = tab.showCounter && counter > 0
        if (counter > 99) (counter = '99+')

        const hasNotices = tasksStore.getters.unreadNoticesByTab[key] > 0

        const selected = key === this.value

        const showDeadlineIcon = (
          this.isDev &&
          tasksStore.getters.hasMissedDeadlines &&
          key === 'expired'
        )

        return {
          ...tab,
          counter,
          hasNotices,
          selected,
          showCounter,
          showDeadlineIcon,
        }
      })
    },
    statusText () {
      return capitalize(this.$t('glossary.status'))
    },
  },
}
