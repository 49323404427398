//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { Portal, PortalTarget } from 'portal-vue'
import { attachLazyPopout, extractPopoutInstance, attachInstance, ATTRIBUTE_ANCHOR_POPOUT_ID } from './Popout.ts'
import DOMUtils from '@/utils/DOM'
import store from '@/store'
import i18n from '@/i18n'

export default {
  name: 'PopoutComponent',
  components: {
    portal: Portal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    behaviour: {
      type: Object,
      default: null,
    },
    appearance: {
      type: Object,
      default: null,
    },
    slimPortal: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    attachLazy: {
      type: Boolean,
      default: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isInitialized: false,
    }
  },
  watch: {
    value (value) {
      value ? this.open() : this.close()
    },
  },
  mounted () {
    if (this.attachLazy) {
      this.init()
      this.popoutParams && (this.clickHandler = attachLazyPopout(this.popoutParams))
    }
  },
  beforeDestroy () {
    this.close(true)
  },
  methods: {
    open () {
      let popout = null
      if (!this.popoutParams) {
        this.init()
        this.popoutParams && (popout = attachInstance(this.popoutParams))
      }

      popout = popout || this.getPopout()
      if (!popout) return

      !popout.isVisible() && popout.show()
    },
    close (clear) {
      const popout = this.getPopout()
      if (!popout) return

      this.isInitialized = false

      popout.isVisible() && popout.hide()

      clear = clear || this.clear
      if (!clear) return

      if (this.portalInstance) {
        this.portalInstance.$destroy()
        const { $el: portalElement } = this.portalInstance
        portalElement && portalElement.remove()

        this.portalInstance = null
      }

      popout.destroy()

      const parentElement = this.getParentElement()
      if (parentElement) {
        this.clickHandler && parentElement.removeEventListener('click', this.clickHandler)
        parentElement.removeAttribute(ATTRIBUTE_ANCHOR_POPOUT_ID)
      }

      this.popoutParams = null
    },
    init () {
      if (this.popoutParams) return this.popoutParams

      const parentElement = this.getParentElement()
      if (!parentElement) return null

      parentElement.setAttribute(ATTRIBUTE_ANCHOR_POPOUT_ID, this.id)

      const staticBehaviour = {
        onBeforeShow: (anchor, element) => {
          if (!element) return

          if (this.fullWidth) {
            const width = anchor.clientWidth
            element.style.width = `${width}px`
          }

          if (!isNaN(this.padding)) {
            element.style.padding = `${this.padding || 0}px`
          }

          if (this.isInitialized) return

          element.style.visibility = 'hidden'

          const targetElement = element.firstChild
          if (!targetElement || targetElement.getAttribute('data-portal-target') !== this.id) return

          const propsData = { slim: this.slimPortal, name: this.id }
          this.portalInstance = new Vue(Object.assign({
            store,
            i18n: i18n,
          }, PortalTarget, { propsData }))
          this.portalInstance.$mount(targetElement)
        },
        onShow: () => {
          this.$emit('input', true)

          if (this.isInitialized) return

          this.isInitialized = true
          this.$nextTick(() => {
            const popout = this.updatePosition()

            const { element } = popout
            element && (element.style.visibility = 'visible')
          })
        },
        onHide: () => {
          this.$emit('input', false)
        },
      }

      this.popoutParams = {
        behaviour: Object.assign({}, { flank: 'bottom' }, this.behaviour || {}, staticBehaviour),
        appearance: this.appearance,
        anchor: parentElement,
        content: () => DOMUtils.createElement('div', { 'data-portal-target': this.id }),
      }
      return this.popoutParams
    },
    getParentElement () {
      return this.$el.parentElement
    },
    getPopout () {
      const parentElement = this.getParentElement()
      if (!parentElement) return

      return extractPopoutInstance(parentElement)
    },
    updatePosition () {
      const popout = this.getPopout()
      popout && popout.recalculatePosition()

      return popout
    },
  },
}
