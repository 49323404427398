













import { contactsStore } from '@/store'
import { getTimeFromNow } from '@/utils'
import { date } from 'quasar'
import { Component, Prop, Vue } from 'vue-property-decorator'

const { isValid } = date

@Component
export default class OnlineStatus extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly chatId !: string

  get onlineText () : string {
    return this.isOnline
      ? this.$t('common.online').toString()
      : this.lastActivity()
  }

  get isOnline (): boolean {
    return contactsStore.getters.contactOnlineType(this.chatId) !== 'none'
  }

  get isAfk (): boolean {
    return contactsStore.getters.contactIsAfk(this.chatId)
  }

  /**
  * when server send server.online component will rerender,
  * important, lastActivity should be method, not computed
  */
  lastActivity (): string {
    const result = this.$t('common.offline').toString()

    const contact = contactsStore.getters.contact(this.chatId)
    if (!contact) return result

    const { lastActivity } = contact
    if (!lastActivity) return result

    const ago = this.getTimeAgo(lastActivity)
    if (!ago) return result

    return this.$t('common.wasOnline', { ago }).toString()
  }

  getTimeAgo (sourceDate: string): string {
    if (!sourceDate || !isValid(sourceDate)) return ''

    return getTimeFromNow(new Date(sourceDate)) ?? ''
  }
}
