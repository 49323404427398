//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultLogger } from '@/loggers'
import { Events, TaskEventBus } from './EventBus'
import { mapGetters, mapActions } from 'vuex'
import { sortTasks } from '@/utils'
import * as actionTypes from '@/store/actionTypes'
import api from '@/api/v3/'
import ScrollableArea from '@/components/UI/ScrollableArea'
import TaskList from './TaskListRefactor'
import TasksItemsFilter from './TasksItemsFilter'
import TasksItemsSort from './TasksItemsSort'
import TasksTabs from './TasksTabs'
import { format } from 'quasar'
import {
  sectionsStore,
  contactsStore,
  tasksStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'

const { capitalize } = format

export default {
  name: 'Tasks',
  components: {
    IconCog: () => import('@/components/UI/icons/IconCog'),
    IconSettings: () => import('@/components/UI/icons/IconSettings'),
    IconBellOff: () => import('@/components/UI/icons/IconBellOff'),
    MemberPicker: () => import('@/components/Modals/Default/MemberPicker/index.vue'),
    ScrollableArea,
    TagSelector: () => import('@/components/UI/TagSelector'),
    TaskList,
    TaskPopup: () => import('./TaskPopup'),
    TasksItemsFilter,
    TasksItemsSort,
    TasksMenu: () => import('./TasksMenu'),
    TasksSearchInput: () => import('./TasksSearchInput'),
    TasksTabs,
  },
  data () {
    return {
      searchResultsJids: [],

      // FILTERS
      searchInputShown: false,
      showFilters: false,

      tabKey: null,

      pagination: {
        limit: 50,
        offset: null,
        count: 0,
      },
      paginationLimit: 50,

      isLoading: false,

      seenTasks: [],
    }
  },
  watch: {
    taskTabsLoading: function (loading) {
      if (!loading) this.loadTasks()
    },
    unreadFirst: function () {
      this.restartSearch()
    },
  },
  created () {
    TaskEventBus.$on(Events.SET_TASK_SEARCH, val => {
      this.showFilters = true
      this.handleTagInput([val])
    })
    TaskEventBus.$on(Events.SET_SECTION_FILTER, val => {
      this.showFilters = true
      this.handleSectionInput(val)
    })
    TaskEventBus.$on(Events.SET_ASSIGNEE_FILTER, value => {
      this.showFilters = true
      this.resetFilters()
      uiStore.actions.updateRightBarTasksSortFilters({
        key: 'assignees',
        value,
      })
      this.restartSearch()
    })
    const lastOpenTasksTab = uiSettingsStore.getters.lastOpenTasksTab
    // if among the possible statuses of tasks there is no last selected one, we take the first of the possible
    // this is possible when moving from team to team
    this.tabKey = lastOpenTasksTab in tasksStore.state.tabs
      ? lastOpenTasksTab
      : this.defaultTabKey
    this.loadTasks()

    !!this.tasksFiltersAndSortParams.search && (this.searchInputShown = true)
  },
  computed: {
    ...mapGetters([
      'profile',
      'unreadFirst',
    ]),
    tasksFiltersAndSortParams () {
      return uiStore.getters.tasksFiltersAndSortParams
    },

    taskTabsLoading () {
      return Object.keys(tasksStore.state.tabs).length < 1
    },

    taskPlural () {
      return capitalize(this.$t('glossary.task_plural'))
    },

    serverSideOnly () {
      if (this.tasksFiltersAndSortParams.search?.trim()?.length > 0) return true
      return false
    },

    defaultTabKey () {
      return Object.keys(tasksStore.state.tabs)[0]
    },

    allowedFilters () {
      // check if field (filter) is present in tab received from backend
      const checkFn = field => this.selectedTab.filters?.some(
        filter => filter.field === field,
      )

      // list of supported filters checked for availability
      return {
        section: checkFn('section'),
        tag: checkFn('tag'),
        assignee: checkFn('assignee'),
        owner: checkFn('owner'),
      }
    },

    canCreateTask () {
      return this.profile.canCreateTask || false
    },

    selectedTab () {
      const key = this.tabKey ?? this.defaultTabKey
      return tasksStore.state.tabs[key]
    },

    defaultSort () {
      return this.selectedTab.sort[0].key
    },

    sort () {
      return this.tasksFiltersAndSortParams.sort || this.defaultSort
    },

    compactList () {
      const { me } = teamsStore.getters.currentTeam
      return me.taskShortView
    },

    selectedSectionObject () {
      return sectionsStore.state.task[this.tasksFiltersAndSortParams.section] ?? null
    },

    sectionsForFilter () {
      const sections = sectionsStore.state.task
      return Object.values(sections)
        .filter(section => !section.isArchive)
    },

    canManageProjects () {
      return teamsStore.getters.currentTeam.me.canManageProjects
    },

    // FILTERS PLACEHOLDERS
    filtersTitles () {
      return Object.fromEntries(this.selectedTab.filters.map(
        ({ field, title }) => [field, title],
      ))
    },
    sectionFilterText () {
      const title = this.filtersTitles.section
      if (this.tasksFiltersAndSortParams.section === undefined) return title
      else if (this.tasksFiltersAndSortParams.section === null) return this.$t('tasks.filters.noProject')
      else return `${title}: ${this.selectedSectionObject.name}`
    },
    assigneeFilterText () {
      const names = this.tasksFiltersAndSortParams.assignees.map(
        uid => contactsStore.getters.contactDisplayName(uid),
      )
      const title = this.filtersTitles.assignee

      if (names.length === 0) return title
      else if (names.length === 1) return `${title}: ${names[0]}`
      // TODO get backend to provide plural title
      else return `${title}: ${names.join(', ')}`
    },
    ownerFilterText () {
      const names = this.tasksFiltersAndSortParams.owners.map(
        uid => contactsStore.getters.contactDisplayName(uid),
      )
      const title = this.filtersTitles.owner

      if (names.length === 0) return title
      else if (names.length === 1) return `${title}: ${names[0]}`
      // TODO get backend to provide plural title
      else return `${title}: ${names.join(', ')}`
    },
    tagFilterText () {
      const tagNames = this.tasksFiltersAndSortParams.tags
      const title = this.filtersTitles.tag

      return tagNames.length > 0
        ? `${title}: ${tagNames.join(', ')}`
        : title
    },

    // FILTERING, SORTING AND POLLING
    filteredTasks () {
      defaultLogger.info('Filtering tasks client side...')

      if (this.serverSideOnly) {
        defaultLogger.info('Search input is not empty, skipping local filtering')
        return []
      }

      defaultLogger.info(
        'Currently on client:',
        Object.keys(tasksStore.state.data).length,
        'tasks',
      )
      const result = Object.values(tasksStore.state.data).filter(task => {
        if (!task.tabs?.includes(this.selectedTab.key)) return false

        if (this.tasksFiltersAndSortParams.section !== undefined &&
          task.section !== this.tasksFiltersAndSortParams.section
        ) return false

        if (this.tasksFiltersAndSortParams.owners.length > 0 &&
          this.tasksFiltersAndSortParams.owners.every(owner => owner !== task.owner)
        ) return false

        if (this.tasksFiltersAndSortParams.assignees.length > 0 &&
          this.tasksFiltersAndSortParams.assignees.every(assignee => assignee !== task.assignee)
        ) return false

        if (this.tasksFiltersAndSortParams.tags.length > 0 &&
          this.tasksFiltersAndSortParams.tags.every(tag => !task.tags?.includes(tag))
        ) return false

        if (this.tasksFiltersAndSortParams.search.length > 0 &&
          !task.displayName.includes(this.tasksFiltersAndSortParams.search)
        ) return false

        return true
      })

      defaultLogger.info('Found on client side:', result.length)
      return result
    },
    sortedTasks () {
      defaultLogger.info('Sorting tasks...', this.filteredTasks.length)

      // separate pinned
      const pinnedTasks = []
      const notPinnedTasks = []
      this.filteredTasks.forEach(t => {
        t.pinned ? pinnedTasks.push(t) : notPinnedTasks.push(t)
      })

      // sort pinned by server sort ordering (less - heigher)
      // if sort ordering is same - use gentime (newer - higher)
      // latter happens in between chat.updated socket events
      pinnedTasks.sort((t1, t2) => (
        t1.pinnedSortOrdering - t2.pinnedSortOrdering ||
        t2.gentime - t1.gentime
      ))

      if (!this.unreadFirst) {
        return [...pinnedTasks, ...this.sortFn(notPinnedTasks)]
      }

      // separate read from unread
      const unreadTasks = []
      const readTasks = []
      notPinnedTasks.forEach(task => {
        task.numUnread ? unreadTasks.push(task) : readTasks.push(task)
      })

      return [
        ...pinnedTasks,
        ...this.sortFn(unreadTasks),
        ...this.sortFn(readTasks),
      ]
    },
    searchResults () {
      const results = []
      this.searchResultsJids.forEach(jid => {
        const task = tasksStore.state.data[jid]
        task && results.push(task)
      })
      return results
    },
    requestParams () {
      const params = { ...this.activeFilters }
      params.sort = this.sort
      params.q = this.tasksFiltersAndSortParams.search
      params.unread_first = this.unreadFirst

      defaultLogger.info('Formed server request params:', params)
      return params
    },
    activeFilters () {
      const assignee = this.tasksFiltersAndSortParams.assignees.length > 0
        ? this.tasksFiltersAndSortParams.assignees
        : null
      const owner = this.tasksFiltersAndSortParams.owners.length > 0
        ? this.tasksFiltersAndSortParams.owners
        : null
      const tag = this.tasksFiltersAndSortParams.tags.length > 0
        ? this.tasksFiltersAndSortParams.tags
        : null
      const section = this.tasksFiltersAndSortParams.section === null
        ? '-'
        : this.tasksFiltersAndSortParams.section ?? null

      const params = {
        assignee,
        owner,
        tag,
        section,
      }

      // cleanup null keys
      Object.keys(params).forEach(key => {
        if (params[key] === null) delete params[key]
      })

      defaultLogger.info('Updated active filters', params)
      return params
    },
    numActiveFilters () {
      return Object.keys(this.activeFilters).length
    },
  },
  methods: {
    ...mapActions([
      actionTypes.UPDATE_CHAT,
    ]),

    restartSearch () {
      this.seenTasks = []
      this.clearServerSearch()
      this.loadTasks()
      this.$refs.taskList && this.$refs.taskList.scrollTop()
    },

    // CHANGING TAB, SORT, FILTERS, AND REACTING
    handleTabInput (tabKey) {
      this.tabKey = tabKey
      uiStore.actions.updateRightBarTasksSortFilters({ key: 'sort', value: null })
      // this.resetFilters() // may be next time
      uiSettingsStore.actions.updateLastOpenTasksTab(tabKey)
      this.restartSearch()
    },
    handleSortInput (sortKey) {
      const supportedSortKeys = [
        'deadline',
        'new',
        'old',
        'activity',
        'num_unread',
        'done',
      ]
      if (!supportedSortKeys.includes(sortKey)) {
        this.$sentry.withScope(scope => {
          scope.setLevel('fatal')
          scope.setTag('tasks', 'right bar')
          scope.setContext('tabs', tasksStore.state.tabs)
          this.$sentry.captureException(
            new Error(`Unexpected sort option: ${sortKey}`),
          )
        })
      }
      uiStore.actions.updateRightBarTasksSortFilters({ key: 'sort', value: sortKey })
      this.restartSearch()
    },
    handleSearchInput (val) {
      uiStore.actions.updateRightBarTasksSortFilters({ key: 'search', value: val })

      // Yandex Metrika goal for tasks searching
      window.goal('search', { searchType: 'Поиск по задачам в правой колонке' })

      this.restartSearch()
    },
    handleSectionInput (uid) {
      if (uid !== this.tasksFiltersAndSortParams.section) {
        uiStore.actions.updateRightBarTasksSortFilters({ key: 'section', value: uid })
        this.restartSearch()
      }
      this.$refs.filterSection.close()
    },
    handleAssigneesInput (assignees) {
      if (assignees.length === this.tasksFiltersAndSortParams.assignees.length) {
        if (this.tasksFiltersAndSortParams.assignees.every(uid => assignees.includes(uid))) {
          defaultLogger.info('Assignees didnt change')
          return // if nothing really changed
        }
      }
      uiStore.actions.updateRightBarTasksSortFilters({ key: 'assignees', value: assignees })
      this.restartSearch()
    },
    handleOwnerInput (owners) {
      if (owners.length === this.tasksFiltersAndSortParams.owners.length) {
        if (this.tasksFiltersAndSortParams.owners.every(uid => owners.includes(uid))) {
          defaultLogger.info('Owners didnt change')
          return // if nothing really changed
        }
      }
      uiStore.actions.updateRightBarTasksSortFilters({ key: 'owners', value: owners })
      this.restartSearch()
    },
    handleTagInput (tags) {
      if (tags.length === this.tasksFiltersAndSortParams.tags.length) {
        if (this.tasksFiltersAndSortParams.tags.every(tag => tags.includes(tag))) {
          defaultLogger.info('Tags didnt change')
          return // if nothing really changed
        }
      }
      uiStore.actions.updateRightBarTasksSortFilters({ key: 'tags', value: tags })
      this.restartSearch()
    },
    clearServerSearch () {
      this.searchResultsJids = []
      this.clearPagination()
    },
    clearPagination () {
      this.pagination = {
        limit: this.paginationLimit,
        offset: null,
        count: 0,
      }
    },
    async loadTasks () {
      if (this.taskTabsLoading) return
      defaultLogger.info('Loading more tasks...')
      this.isLoading = true

      const params = { ...this.requestParams }

      if (this.selectedTab.pagination) {
        const offset = this.pagination.offset
        if (!offset || offset < this.pagination.count) {
          params.offset = offset
          params.limit = this.pagination.limit
        } else {
          this.isLoading = false
          return // break loading
        }
      }

      let newTasks = []
      try {
        const response = await api.tasks.getByTab(this.selectedTab.key, params)
        defaultLogger.info('Received from server ', response.objects.length, 'of ', response.count)
        if (response.count >= 0) {
          this.pagination.count = response.count
        }
        if (response.offset >= 0) {
          this.pagination.offset = response.offset + this.pagination.limit
        }

        newTasks = response.objects.filter(
          task => !Object.keys(tasksStore.state.data).includes(task.jid),
        )
        if (newTasks.length > 0) {
          defaultLogger.info('Some of received tasks were not in store', newTasks)
          tasksStore.mutations.addTasks(newTasks)
          response.chats.forEach(c => this.UPDATE_CHAT(c))
        }

        this.searchResultsJids.push(...response.objects.map(task => task.jid))
      } catch (e) {
        defaultLogger.error('Error loading', e, params)
      }

      this.isLoading = false
      newTasks.length > 0 && this.handleSeenTask()
    },

    handleSeenTask (jid) {
      jid && !this.seenTasks.includes(jid) && this.seenTasks.push(jid)
      this.needToLoadMore() && !this.isLoading && this.loadTasks()
    },

    needToLoadMore () {
      const numUnseenTasksTotal = this.filteredTasks.length - this.seenTasks.length
      const maxUnseenTasks = 20
      if (numUnseenTasksTotal <= maxUnseenTasks) {
        defaultLogger.info('Need to load more - total unseen threshold')
        defaultLogger.info(
          'total unseen', numUnseenTasksTotal,
          'threshold', maxUnseenTasks,
        )
        return true
      }

      const numUnseenTasksServer = this.searchResultsJids.filter(jid => !this.seenTasks.includes(jid)).length
      const maxUnseenFromServer = 5
      if (numUnseenTasksServer <= maxUnseenFromServer) {
        defaultLogger.info('Need to load more - unseen from server threshold')
        defaultLogger.info(
          'unseen from server', numUnseenTasksServer,
          'threshold', maxUnseenFromServer,
        )
        return true
      }

      const lastSeen = this.seenTasks[this.seenTasks.length - 1]
      const lastSeenIndex = this.sortedTasks.findIndex(t => t.jid === lastSeen)
      const numUnseenBelow = this.sortedTasks.length - lastSeenIndex
      const maxUnseenBelow = 10
      if (numUnseenBelow <= maxUnseenBelow) {
        defaultLogger.info('Need to load more - unseen below threshold')
        defaultLogger.info(
          'unseen below', numUnseenBelow,
          'threshold', maxUnseenBelow,
        )
        return true
      }

      return false
    },

    sortFn (tasks) {
      return sortTasks(tasks, this.sort)
    },

    // MISC
    showNewTaskModal () {
      uiStore.actions.showModal({ instance: 'new-task', payload: null })
      window.goal('taskControls', { taskControls: 'Создать задачу − «Создать» в правой колонке' })
    },
    editSection (uid) {
      uiStore.actions.showModal({ instance: 'edit-section', payload: { uid } })
    },
    compareDates (date1, date2, ascending = true) {
      let result = 0

      if (date1 && !date2) return -1
      else if (!date1 && date2) return 1
      else if (date1 && date2) result = Date.parse(date2) - Date.parse(date1)
      else return result

      return result * (ascending ? -1 : 1)
    },
    resetFilters () {
      this.handleAssigneesInput([])
      this.handleOwnerInput([])
      this.handleSectionInput()
      this.handleTagInput([])
    },
  },
}
